import Api from '@services/api';
import { ChildDocument, Document } from '@types';
import { DOCUMENT_SECTIONS } from 'constants/documents';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface DocumentsState {
  documents: Document[];
  isLoading: boolean;
  error: string | null;
  lastFetch: number | null;
  fetchDocuments: (section?: string, force?: boolean) => Promise<void>;
  uploadDocument: (file: File, type: string, section: string) => Promise<Document>;
  deleteDocument: (documentId: string) => Promise<void>;
  updateDocument: (documentId: string, updates: Partial<Document>) => Promise<void>;
  setError: (error: string | null) => void;
  resetStore: () => void;
  groupDocumentsBySection: () => Record<string, Document[]>;
  downloadDocument: (documentId: string, childId?: string) => Promise<Blob>;
  downloadAllDocuments: (section?: string, documentIds?: string[]) => Promise<Blob>;
  deleteChildDocument: (parentId: string, childId: string) => Promise<void>;
  updateChildDocument: (
    parentId: string,
    childId: string,
    updates: Partial<ChildDocument>,
  ) => Promise<ChildDocument>;
  uploadChildDocuments: (parentId: string, files: File[]) => Promise<ChildDocument[]>;
}

export const useDocumentsStore = create<DocumentsState>()(
  persist(
    (set, get) => ({
      documents: [],
      isLoading: false,
      error: null,
      lastFetch: null,

      fetchDocuments: async (section?: string, force = false) => {
        const now = Date.now();
        const lastFetch = get().lastFetch;
        const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

        // Return cached data if within cache duration
        if (!force && lastFetch && now - lastFetch < CACHE_DURATION) {
          return;
        }

        set({ isLoading: true, error: null });
        try {
          const documents = await Api.getDocuments(undefined, section);
          set({
            documents,
            isLoading: false,
            lastFetch: now,
          });
        } catch (error) {
          console.error('Error fetching documents:', error);
          set({ error: 'Falha ao buscar documentos', isLoading: false, lastFetch: null });
        }
      },

      uploadDocument: async (file: File, type: string, section: string) => {
        set({ isLoading: true, error: null });
        try {
          const newDocument = await Api.uploadDocument(file, type, undefined, section);
          set((state) => ({
            documents: [...state.documents, newDocument],
            isLoading: false,
          }));
          return newDocument as Document;
        } catch (error) {
          console.error('Error uploading document:', error);
          set({ error: 'Falha ao fazer upload do documento', isLoading: false });
          throw error;
        }
      },

      deleteDocument: async (documentId: string) => {
        set({ isLoading: true, error: null });
        try {
          await Api.deleteDocument(documentId);
          set((state) => ({
            documents: state.documents.filter((doc) => doc.id !== documentId),
            isLoading: false,
          }));
        } catch (error) {
          console.error('Error deleting document:', error);
          set({ error: 'Falha ao excluir o documento', isLoading: false });
        }
      },

      updateDocument: async (documentId: string, updates: Partial<Document>) => {
        set({ isLoading: true, error: null });
        try {
          const formattedUpdates = {
            ...updates,
            generateDate: updates.generateDate
              ? new Date(updates.generateDate).toISOString().split('T')[0]
              : null,
            expireDate: updates.expireDate
              ? new Date(updates.expireDate).toISOString().split('T')[0]
              : null,
          };
          const updatedDocument = await Api.updateDocument(documentId, formattedUpdates);
          set((state) => ({
            documents: state.documents.map((doc) =>
              doc.id === documentId ? { ...doc, ...updatedDocument } : doc,
            ),
            isLoading: false,
          }));
        } catch (error) {
          console.error('Error updating document:', error);
          set({ error: 'Falha ao atualizar o documento', isLoading: false });
        }
      },

      setError: (error: string | null) => set({ error }),

      resetStore: () => set({ documents: [], isLoading: false, error: null }),

      groupDocumentsBySection: () => {
        const groupedDocuments: Record<string, Document[]> = {};
        DOCUMENT_SECTIONS.forEach((section) => {
          groupedDocuments[section.id] = get().documents.filter(
            (doc) =>
              section.documents.some((sectionDoc) => sectionDoc.id === doc.type) ||
              doc.section === section.id,
          );
        });
        return groupedDocuments;
      },

      downloadDocument: async (documentId: string, childId?: string) => {
        set({ isLoading: true, error: null });
        try {
          const blob = await Api.downloadDocument(documentId, childId);
          set({ isLoading: false });
          return blob;
        } catch (error) {
          console.error('Error downloading document:', error);
          set({ error: 'Falha ao baixar o documento', isLoading: false });
          throw error;
        }
      },

      downloadAllDocuments: async (section?: string, documentIds?: string[]) => {
        try {
          set({ isLoading: true, error: null });
          const blob = await Api.downloadAllDocuments(section, documentIds);
          return blob;
        } catch (error) {
          console.error('Error downloading all documents:', error);
          set({ error: 'Falha ao baixar todos os documentos', isLoading: false });
          throw error;
        } finally {
          set({ isLoading: false });
        }
      },

      deleteChildDocument: async (parentId: string, childId: string) => {
        set({ isLoading: true, error: null });
        try {
          await Api.deleteChildDocument(parentId, childId);
          set((state) => ({
            documents: state.documents.map((doc) =>
              doc.id === parentId
                ? {
                    ...doc,
                    documents: (doc.documents || []).filter((child) => child.id !== childId),
                    updatedAt: new Date().toISOString(),
                  }
                : doc,
            ),
            isLoading: false,
          }));
        } catch (error) {
          console.error('Error deleting child document:', error);
          set({ error: 'Falha ao excluir o documento filho', isLoading: false });
          throw error;
        }
      },

      updateChildDocument: async (
        parentId: string,
        childId: string,
        updates: Partial<ChildDocument>,
      ) => {
        set({ isLoading: true, error: null });
        try {
          const updatedChild = await Api.updateChildDocument(parentId, childId, updates);
          set((state) => ({
            documents: state.documents.map((doc) =>
              doc.id === parentId
                ? {
                    ...doc,
                    documents: doc.documents?.map((child) =>
                      child.id === childId ? { ...child, ...updatedChild } : child,
                    ),
                    updatedAt: new Date().toISOString(),
                  }
                : doc,
            ),
            isLoading: false,
          }));
          return updatedChild;
        } catch (error) {
          console.error('Error updating child document:', error);
          set({ error: 'Falha ao atualizar o documento filho', isLoading: false });
          throw error;
        }
      },

      uploadChildDocuments: async (parentId: string, files: File[]) => {
        set({ isLoading: true, error: null });
        try {
          const newDocuments = await Api.uploadChildDocuments(parentId, files);

          set((state) => ({
            documents: state.documents.map((doc) =>
              doc.id === parentId
                ? {
                    ...doc,
                    documents: [
                      ...(doc.documents || []),
                      ...newDocuments.map((newDoc) => ({
                        id: newDoc.id,
                        name: newDoc.name,
                        customName: newDoc.customName,
                        url: newDoc.url,
                        createdAt: newDoc.createdAt,
                        updatedAt: newDoc.updatedAt,
                      })),
                    ],
                    updatedAt: new Date().toISOString(),
                  }
                : doc,
            ),
            isLoading: false,
          }));

          return newDocuments;
        } catch (error) {
          console.error('Error uploading child documents:', error);
          set({ error: 'Falha ao fazer upload dos documentos filhos', isLoading: false });
          throw error;
        }
      },
    }),
    {
      name: 'documents-storage',
      partialize: (state) => ({
        documents: state.documents,
        lastFetch: state.lastFetch,
      }),
    },
  ),
);
