import { SxProps } from '@mui/material';
import React from 'react';
import ChipInput from './ChipInput';

interface KeywordsProps {
  keywords: string[];
  onChange: (keywords: string[]) => void;
  placeholder?: string;
  maxKeywords?: number;
  onInputChange?: (inputValue: string) => void;
  sx?: SxProps;
}

const Keywords: React.FC<KeywordsProps> = ({
  keywords,
  onChange,
  placeholder = 'Digite uma palavra-chave e pressione Enter',
  onInputChange = () => {},
  sx = {},
}) => {
  const handleChange = (newKeywords: string[]) => {
    onChange(newKeywords);
  };

  const handleInputChange = (inputValue: string) => {
    onInputChange(inputValue);
  };

  return (
    <>
      <ChipInput
        value={keywords}
        onChangeValue={handleChange}
        placeholder={placeholder}
        onInputChange={handleInputChange}
        sx={sx}
      />
    </>
  );
};

export default Keywords;
