import { Analytics, getAnalytics, logEvent } from '@firebase/analytics';
import { initializeApp } from '@firebase/app';
import { getAuth } from '@firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDEXY-F6cyhkXj6kSFosnm7s5ktV2P5-_k',
  authDomain: 'licitou.com.br',
  projectId: 'licitou-edd06',

  // You can remove the following lines if you're not using these services
  // storageBucket: 'licitou-edd06.appspot.com',
  // messagingSenderId: '848402392913',
  appId: '1:848402392913:web:e06a13ead24e78026c5570',
  measurementId: 'G-5FBWXM6Q5V',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics: Analytics = getAnalytics(app);

export { analytics, auth };

// Analytics helper functions
export const trackEvent = (eventName: string, eventParams?: Record<string, any>) => {
  try {
    logEvent(analytics, eventName, eventParams);
  } catch (error) {
    console.error('Analytics error:', error);
  }
};

export const trackPageView = (pageName: string, pageParams?: Record<string, any>) => {
  trackEvent('page_view', {
    page_title: pageName,
    page_location: window.location.href,
    page_path: window.location.pathname,
    ...pageParams,
  });
};
