import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values: any) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator="."
        decimalSeparator=","
        valueIsNumericString
        prefix="R$ "
        decimalScale={2}
        fixedDecimalScale
      />
    );
  },
);

interface CurrencyInputProps extends Omit<TextFieldProps, 'onChange'> {
  onChange: (value: string) => void;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({ onChange, ...props }) => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
      }}
    />
  );
};

export default CurrencyInput;
