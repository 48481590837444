import { Box, keyframes, useTheme } from '@mui/material';
import { useColorScheme } from '@mui/material/styles';
import RotatingLogo from './RotatingLogo';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const SuspenseLoading = () => {
  const { mode } = useColorScheme();
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:
          mode === 'dark'
            ? 'hsla(220, 25%, 10%, 0.9)' // gray[900] with opacity
            : 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(8px)',
        zIndex: 9999,
        animation: `${fadeIn} 0.3s ease-in-out`,
      }}
    >
      <RotatingLogo color={theme.palette.primary.main} />
    </Box>
  );
};

export default SuspenseLoading;
