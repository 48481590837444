import FeedbackButton from '@components/Layout/FeedbackButton';
import Header from '@components/Layout/Header';
import SubscriptionReminder from '@components/Payment/SubscriptionReminder';
import OnboardingReminder from '@components/Tour/OnboardingReminder';
import { useAuth } from '@contexts/AuthContext';
import { getTourIdFromPath } from '@contexts/TourContext';
import { useAnalytics } from '@hooks/useAnalytics';
import { useTour } from '@hooks/useTour';
import { useTrialCheck } from '@hooks/useTrialCheck';
import { Box, CircularProgress, Container, useTheme } from '@mui/material';
import { ApiService } from '@services/api';
import { useProfileStore } from '@store/profileStore';
import { isBefore, parseISO } from 'date-fns';
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import IntercomChat from './Layout/IntercomChat';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { user, isLoading } = useAuth();
  const { company, userPreferences, userData } = useProfileStore();
  const location = useLocation();
  const theme = useTheme();
  const tourId = getTourIdFromPath(location.pathname);
  const { trackEvent, trackPageView } = useAnalytics();

  useEffect(() => {
    if (user && userData) {
      trackPageView(document.title, {
        path: location.pathname,
        company_id: company?.razaoSocial,
        subscription_status: userData.subscriptionStatus,
        is_trial: userData.subscriptionStatus === 'trialing',
        is_onboarding_complete: Boolean(
          company?.cnaePrincipal?.id && userPreferences?.companySummary,
        ),
      });

      // Track specific user states
      if (userData.subscriptionStatus === 'past_due') {
        trackEvent('subscription_past_due_view');
      }
      if (userData.subscriptionStatus === 'trialing') {
        trackEvent('trial_user_view');
      }
    }
  }, [location.pathname, user, userData]);

  useTour({
    tourId,
    onComplete: () => {
      // Optional: Add any global tour completion logic here
    },
  });

  if (!isLoading && !user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const opacity = isLoading ? 0.7 : 1;

  useTrialCheck();

  const isTrialExpired =
    userData?.subscriptionStatus === 'trialing' &&
    userData.gracePeriodEnd &&
    isBefore(parseISO(userData.gracePeriodEnd), new Date());

  useEffect(() => {
    if (isTrialExpired) {
      trackEvent('trial_expired_view', {
        grace_period_end: userData?.gracePeriodEnd,
      });
    }
  }, [isTrialExpired]);

  const shouldRedirectToPayment =
    userData &&
    !userData.isActive &&
    userData.role !== 'admin' &&
    !location.pathname.startsWith('/configuracoes/pagamento') &&
    (userData.subscriptionStatus !== 'trialing' || isTrialExpired) &&
    ApiService.getUnauthorizedCount() === 0;

  if (shouldRedirectToPayment) {
    trackEvent('payment_redirect', {
      from_path: location.pathname,
      subscription_status: userData?.subscriptionStatus,
    });
    return <Navigate to="/configuracoes/pagamento" replace />;
  }

  const isOnboardingComplete =
    userData && (userData.isActive || userData.subscriptionStatus === 'trialing')
      ? Boolean(company?.cnaePrincipal?.id && userPreferences?.companySummary)
      : true;

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        opacity,
        transition: 'opacity 0.2s',
        position: 'relative',
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: theme.spacing(2),
            right: theme.spacing(2),
            zIndex: theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress size={20} />
        </Box>
      )}
      <Container maxWidth={false} disableGutters>
        <Box px={2}>
          <Header />
          {userData && (
            <Box mt={3}>
              {!isOnboardingComplete && <OnboardingReminder />}
              {userData.subscriptionStatus === 'past_due' && <SubscriptionReminder />}
              {userData.subscriptionStatus === 'trialing' && <SubscriptionReminder />}
            </Box>
          )}
          <Box my={4}>{children}</Box>
        </Box>
      </Container>
      <FeedbackButton />
      <IntercomChat />
    </Box>
  );
};

export default PrivateRoute;
