import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import ItemsPerPageSelect from './ItemsPerPageSelect';
import ViewFormatToggle from './ViewFormatToggle';

interface ListControlsProps {
  itemsPerPage: number;
  onItemsPerPageChange: (value: number) => void;
  viewFormat: 'list' | 'column';
  onViewFormatChange: (format: 'list' | 'column') => void;
  onRefresh: () => void;
  disabled?: boolean;
}

const ListControls: React.FC<ListControlsProps> = ({
  itemsPerPage,
  onItemsPerPageChange,
  viewFormat,
  onViewFormatChange,
  onRefresh,
  disabled,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <ItemsPerPageSelect
        value={itemsPerPage}
        onChange={onItemsPerPageChange}
        disabled={disabled}
      />
      {!isMobile && (
        <ViewFormatToggle value={viewFormat} onChange={onViewFormatChange} disabled={disabled} />
      )}
      <Tooltip title="Atualizar">
        <span>
          <IconButton
            onClick={onRefresh}
            disabled={disabled}
            size="small"
            sx={{
              color: 'primary.main',
              width: 32,
              height: 32,
            }}
          >
            <RefreshIcon fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export default ListControls;
