import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Fab, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

const BackToTop: React.FC = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.pageYOffset > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {showBackToTop && (
        <Fab
          color="primary"
          size="small"
          aria-label="scroll back to top"
          onClick={handleBackToTop}
          sx={{
            position: 'fixed',
            bottom: theme.spacing(12),
            right: theme.spacing(2),
            zIndex: 1000,
          }}
        >
          <ArrowUpwardIcon />
        </Fab>
      )}
    </>
  );
};

export default BackToTop;
