import { DEFAULT_BOARD_ID, DEFAULT_BOARD_NAME, FIXED_KANBAN_COLUMNS } from '@constants';
import Api from '@services/api';
import { KanbanBoard, KanbanColumn, KanbanLicitacao } from '@types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface KanbanState {
  board: KanbanBoard;
  isLoading: boolean;
  error: string | null;
  lastFetch: number | null;
  fetchBoard: (forceRefresh?: boolean) => Promise<void>;
  addKanbanLicitacoes: (
    columnId: string,
    licitacaoIds: string[],
    extraData: Partial<KanbanLicitacao>,
  ) => Promise<void>;
  updateKanbanLicitacao: (licitacaoId: string, updates: Partial<KanbanLicitacao>) => Promise<void>;
  removeKanbanLicitacao: (licitacaoId: string) => Promise<void>;
  moveKanbanLicitacao: (
    licitacaoId: string,
    newColumnId: string,
    newPosition: number,
  ) => Promise<void>;
  columns: KanbanColumn[];
  setColumns: (columns: KanbanColumn[]) => void;
  resetStore: () => void;
}

export const useKanbanStore = create<KanbanState>()(
  persist(
    (set, get) => ({
      board: {
        id: DEFAULT_BOARD_ID,
        title: DEFAULT_BOARD_NAME,
        columns: FIXED_KANBAN_COLUMNS,
      },
      isLoading: false,
      error: null,
      lastFetch: null,

      fetchBoard: async (forceRefresh = false) => {
        const now = Date.now();
        const lastFetch = get().lastFetch;
        const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

        if (!forceRefresh && lastFetch && now - lastFetch < CACHE_DURATION) {
          return;
        }

        set({ isLoading: true, error: null });
        try {
          const boardData = await Api.getKanbanBoard();
          set({
            board: boardData,
            lastFetch: now,
          });
        } catch (error) {
          console.error(error);
          set({ error: 'Falha ao buscar o kanban' });
        } finally {
          set({ isLoading: false });
        }
      },

      addKanbanLicitacoes: async (
        columnId: string,
        licitacaoIds: string[],
        extraData: Partial<KanbanLicitacao>,
      ) => {
        try {
          set({ isLoading: true, error: null });
          const updatedBoard = await Api.addKanbanLicitacoes(columnId, licitacaoIds, extraData);
          if (updatedBoard) {
            set({ board: updatedBoard });
          } else {
            set({ error: 'Falha ao adicionar licitações ao kanban' });
          }
        } catch (error) {
          console.error(error);
          set({ error: 'Falha ao adicionar licitações ao kanban' });
        } finally {
          set({ isLoading: false });
        }
      },

      updateKanbanLicitacao: async (licitacaoId: string, updates: Partial<KanbanLicitacao>) => {
        set({ isLoading: true, error: null });
        try {
          const updatedBoard = await Api.updateKanbanLicitacao(licitacaoId, updates);
          set({ board: updatedBoard });
        } catch (error) {
          console.error(error);
          set({ error: 'Falha ao atualizar licitação no kanban' });
        } finally {
          set({ isLoading: false });
        }
      },

      removeKanbanLicitacao: async (licitacaoId: string) => {
        set({ isLoading: true, error: null });
        try {
          const updatedBoard = await Api.removeKanbanLicitacao(licitacaoId);
          set({ board: updatedBoard });
        } catch (error) {
          console.error(error);
          set({ error: 'Falha ao remover licitação do kanban' });
        } finally {
          set({ isLoading: false });
        }
      },

      moveKanbanLicitacao: async (
        licitacaoId: string,
        newColumnId: string,
        newPosition: number,
      ) => {
        set((state) => {
          const updatedColumns = state.board.columns.map((column) => {
            if (column.licitacoes.some((l) => l.id === licitacaoId)) {
              // Remove from old column
              return {
                ...column,
                licitacoes: column.licitacoes.filter((l) => l.id !== licitacaoId),
                licitacoesIds: column.licitacoesIds.filter((id) => id !== licitacaoId),
              };
            }
            if (column.id === newColumnId) {
              // Add to new column
              const movedLicitacao = state.board.columns
                .flatMap((c) => c.licitacoes)
                .find((l) => l.id === licitacaoId);
              if (movedLicitacao) {
                const newLicitacoes = [...column.licitacoes];
                newLicitacoes.splice(newPosition, 0, movedLicitacao);
                return {
                  ...column,
                  licitacoes: newLicitacoes,
                  licitacoesIds: newLicitacoes.map((l) => l.id),
                };
              }
            }
            return column;
          });

          return {
            board: {
              ...state.board,
              columns: updatedColumns,
            },
          };
        });

        set({ isLoading: true, error: null });
        try {
          const updatedBoard = await Api.moveKanbanLicitacao(licitacaoId, newColumnId, newPosition);
          set({ board: updatedBoard });
        } catch (error) {
          console.error(error);
          set({ error: 'Falha ao mover licitação no kanban' });
        } finally {
          set({ isLoading: false });
        }
      },

      columns: [],
      setColumns: (columns) => set({ columns }),

      resetStore: () =>
        set({
          board: {
            id: DEFAULT_BOARD_ID,
            title: DEFAULT_BOARD_NAME,
            columns: FIXED_KANBAN_COLUMNS,
          },
          isLoading: false,
          error: null,
          columns: [],
          lastFetch: null,
        }),
    }),
    {
      name: 'kanban-storage',

      // partialize: (state) => ({ board: state.board }),
      partialize: () => ({}), // Don't persist any state
    },
  ),
);
