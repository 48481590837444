import { useNotification } from '@contexts/NotificationContext';
import { useAnalytics } from '@hooks/useAnalytics';
import FeedbackIcon from '@mui/icons-material/Feedback';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  Typography,
  styled,
} from '@mui/material';
import api from '@services/api';
import React, { useState } from 'react';

const SATISFACTION_LEVELS = [
  { emoji: '😠', label: 'INSATISFEITO' },
  { emoji: '😕', label: 'CONFUSO' },
  { emoji: '😐', label: 'INDIFERENTE' },
  { emoji: '😊', label: 'SATISFEITO' },
  { emoji: '😍', label: 'FELIZ' },
];

const StyledEmoji = styled(Box)(({ selected }: { selected?: boolean }) => ({
  cursor: 'pointer',
  padding: '8px',
  borderRadius: '50%',
  transition: 'transform 0.2s',
  transform: selected ? 'scale(1.2)' : 'scale(1)',
  '&:hover': {
    transform: 'scale(1.2)',
  },
}));

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  minHeight: '100px',
  padding: '10px',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',
  fontFamily: theme.typography.fontFamily,
  fontSize: '1rem',
  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary.main,
  },
}));

interface FeedbackButtonProps {
  position?: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
}

const FeedbackButton: React.FC<FeedbackButtonProps> = ({ position = 'bottom-left' }) => {
  const { trackEvent } = useAnalytics();
  const { showNotification } = useNotification();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState<number | null>(null);
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOpenDialog = () => {
    trackEvent('feedback_dialog_opened', {
      position,
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    trackEvent('feedback_dialog_closed', {
      had_selection: selectedLevel !== null,
      had_feedback: Boolean(feedback.trim()),
    });
    setOpenDialog(false);
    setSelectedLevel(null);
    setFeedback('');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedLevel === null) return;

    setIsSubmitting(true);
    try {
      await api.sendFeedback(
        `[Site Feedback]\nNível de satisfação: ${SATISFACTION_LEVELS[selectedLevel].label}\n\nFeedback: ${feedback}`,
      );
      trackEvent('feedback_sent', {
        satisfaction_level: SATISFACTION_LEVELS[selectedLevel].label,
      });
      showNotification('Feedback enviado com sucesso!', 'success');
      handleCloseDialog();
    } catch (error) {
      trackEvent('feedback_error', {
        error_message: error instanceof Error ? error.message : String(error),
        satisfaction_level: SATISFACTION_LEVELS[selectedLevel].label,
      });
      showNotification(
        'Ocorreu um erro ao enviar o feedback. Por favor, tente novamente.',
        'error',
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const getPositionStyle = () => {
    switch (position) {
      case 'bottom-left':
        return { bottom: '20px', left: '20px' };
      case 'top-left':
        return { top: '20px', left: '20px' };
      case 'top-right':
        return { top: '20px', right: '20px' };
      case 'bottom-right':
      default:
        return { bottom: '20px', right: '20px' };
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<FeedbackIcon />}
        onClick={handleOpenDialog}
        sx={{
          position: 'fixed',
          zIndex: 1000,
          ...getPositionStyle(),
        }}
      >
        Enviar Feedback
      </Button>

      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Ajude-nos a melhorar nossa ferramenta</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            Qual o seu nível de satisfação com o Licitou?
          </Typography>
          <Box display="flex" justifyContent="space-around" mb={3}>
            {SATISFACTION_LEVELS.map((level, index) => (
              <Box key={index} textAlign="center">
                <StyledEmoji
                  selected={selectedLevel === index}
                  onClick={() => setSelectedLevel(index)}
                >
                  <Typography fontSize="2rem">{level.emoji}</Typography>
                </StyledEmoji>
                <Typography variant="caption" display="block">
                  {level.label}
                </Typography>
              </Box>
            ))}
          </Box>
          <Typography variant="subtitle1" gutterBottom>
            Como está sendo utilizar o Licitou
          </Typography>
          <StyledTextarea
            placeholder="Escreva sua experiência aqui..."
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={isSubmitting || selectedLevel === null}
          >
            {isSubmitting ? 'Enviando...' : 'Enviar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FeedbackButton;
