import { Box, List, ListItem, Typography } from '@mui/material';
import React from 'react';
import HighlightedText from './HighlightedText';

interface MatchingItem {
  descricao: string;
  isHighlighted: boolean;
}

interface MatchingItemsProps {
  items: MatchingItem[];
  highlights?: Record<string, string[]>;
}

const MatchingItems: React.FC<MatchingItemsProps> = ({ items, highlights }) => {
  if (items.length === 0) return null;

  return (
    <Box mt={1}>
      <Typography variant="subtitle2" color="primary">
        {items.length === 1 ? 'Item correspondente:' : 'Itens correspondentes:'}
      </Typography>
      <List dense>
        {items.map((item, index) => (
          <ListItem key={index} disableGutters>
            {item.isHighlighted ? (
              <HighlightedText
                field="items.descricao"
                text={item.descricao}
                highlights={highlights}
              />
            ) : (
              <Typography variant="body2">{item.descricao}</Typography>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default MatchingItems;
