export const scrollToTop = (elementId?: string) => {
  // Small delay to ensure content is rendered
  setTimeout(() => {
    if (elementId) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        return;
      }
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, 100);
};
