import {
  AttachMoney,
  Block,
  Campaign,
  Computer,
  Description,
  Email,
  EmojiEvents,
  Forum,
  Gavel,
  Groups,
  HelpOutline,
  ListAlt,
  RequestQuote,
  Settings,
  ShoppingCart,
  SvgIconComponent,
} from '@mui/icons-material';
import {
  CompanySizeEnum,
  Esfera,
  KanbanColumn,
  LicitacoesSearchParams,
  ModalidadeInfo,
  Portal,
} from '@types';

export const TIME_ZONE = 'America/Sao_Paulo';

export const brazilianStates = [
  { label: 'Acre', value: 'AC' },
  { label: 'Alagoas', value: 'AL' },
  { label: 'Amapá', value: 'AP' },
  { label: 'Amazonas', value: 'AM' },
  { label: 'Bahia', value: 'BA' },
  { label: 'Ceará', value: 'CE' },
  { label: 'Distrito Federal', value: 'DF' },
  { label: 'Espírito Santo', value: 'ES' },
  { label: 'Goiás', value: 'GO' },
  { label: 'Maranhão', value: 'MA' },
  { label: 'Mato Grosso', value: 'MT' },
  { label: 'Mato Grosso do Sul', value: 'MS' },
  { label: 'Minas Gerais', value: 'MG' },
  { label: 'Pará', value: 'PA' },
  { label: 'Paraíba', value: 'PB' },
  { label: 'Paraná', value: 'PR' },
  { label: 'Pernambuco', value: 'PE' },
  { label: 'Piauí', value: 'PI' },
  { label: 'Rio de Janeiro', value: 'RJ' },
  { label: 'Rio Grande do Norte', value: 'RN' },
  { label: 'Rio Grande do Sul', value: 'RS' },
  { label: 'Rondônia', value: 'RO' },
  { label: 'Roraima', value: 'RR' },
  { label: 'Santa Catarina', value: 'SC' },
  { label: 'São Paulo', value: 'SP' },
  { label: 'Sergipe', value: 'SE' },
  { label: 'Tocantins', value: 'TO' },
];

export const tipoMapping = {
  0: 'Sem modalidade definida',
  1: 'Convite',
  2: 'Concorrência',
  3: 'Leilão',
  4: 'Tomada de preços',
  5: 'Pregão eletrônico',
  6: 'Dispensa',
  7: 'Inexigibilidade',
  8: 'Pregão presencial',
  9: 'Concurso',
  10: 'Registro de preço',
  11: 'Chamamento público',
  12: 'Compra eletrônica',
  13: 'Pregão',
  14: 'RDC - Regime diferenciado de contratação',
  15: 'Cotação Eletrônica',
  16: 'Diálogo competitivo',
  17: 'Concorrência eletrônica',
};

export const modalidadesMap: Record<
  number,
  { name: string; icon: SvgIconComponent; color: string; description: string }
> = {
  0: {
    name: 'Sem modalidade definida',
    icon: HelpOutline,
    color: '#999999',
    description: 'Modalidade de licitação não especificada ou não reconhecida.',
  },
  1: {
    name: 'Convite',
    icon: Email,
    color: '#FFA000',
    description:
      'Modalidade entre interessados do ramo, cadastrados ou não, escolhidos em número mínimo de três.',
  },
  2: {
    name: 'Concorrência',
    icon: EmojiEvents,
    color: '#1E88E5',
    description:
      'Modalidade entre quaisquer interessados que comprovem possuir os requisitos mínimos de qualificação.',
  },
  3: {
    name: 'Leilão',
    icon: Gavel,
    color: '#43A047',
    description:
      'Modalidade entre quaisquer interessados para a venda de bens móveis inservíveis ou de produtos legalmente apreendidos.',
  },
  4: {
    name: 'Tomada de preços',
    icon: AttachMoney,
    color: '#E53935',
    description:
      'Modalidade entre interessados devidamente cadastrados, observada a necessária qualificação.',
  },
  5: {
    name: 'Pregão eletrônico',
    icon: Computer,
    color: '#3949AB',
    description:
      'Modalidade realizada à distância em sessão pública, por meio de sistema que promova a comunicação pela internet.',
  },
  6: {
    name: 'Dispensa',
    icon: Description,
    color: '#8E24AA',
    description: 'Contratação direta, sem licitação, nos casos previstos em lei.',
  },
  7: {
    name: 'Inexigibilidade',
    icon: Block,
    color: '#D81B60',
    description: 'Contratação direta quando houver inviabilidade de competição.',
  },
  8: {
    name: 'Pregão presencial',
    icon: Groups,
    color: '#00897B',
    description: 'Modalidade presencial para aquisição de bens e serviços comuns.',
  },
  9: {
    name: 'Concurso',
    icon: EmojiEvents,
    color: '#7CB342',
    description:
      'Modalidade entre quaisquer interessados para escolha de trabalho técnico, científico ou artístico.',
  },
  10: {
    name: 'Registro de preço',
    icon: ListAlt,
    color: '#FB8C00',
    description:
      'Sistema de compras pelo qual os interessados concordam em manter os preços registrados por um período.',
  },
  11: {
    name: 'Chamamento público',
    icon: Campaign,
    color: '#5E35B1',
    description:
      'Procedimento para selecionar organização da sociedade civil para firmar parceria.',
  },
  12: {
    name: 'Compra eletrônica',
    icon: ShoppingCart,
    color: '#00ACC1',
    description:
      'Processo de aquisição de bens e serviços comuns, utilizando a tecnologia da informação.',
  },
  13: {
    name: 'Pregão',
    icon: Gavel,
    color: '#F4511E',
    description: 'Modalidade de licitação para aquisição de bens e serviços comuns.',
  },
  14: {
    name: 'RDC - Regime diferenciado de contratação',
    icon: Settings,
    color: '#6D4C41',
    description:
      'Modalidade que busca ampliar a eficiência nas contratações públicas e a competitividade entre os licitantes.',
  },
  15: {
    name: 'Cotação Eletrônica',
    icon: RequestQuote,
    color: '#546E7A',
    description: 'Procedimento para aquisição de bens de pequeno valor por dispensa de licitação.',
  },
  16: {
    name: 'Diálogo competitivo',
    icon: Forum,
    color: '#C0CA33',
    description:
      'Modalidade de licitação para contratação de obras, serviços e compras em que a administração pública realiza diálogos com licitantes previamente selecionados.',
  },
  17: {
    name: 'Concorrência eletrônica',
    icon: Computer,
    color: '#8D6E63',
    description:
      'Modalidade de licitação realizada de forma eletrônica, entre quaisquer interessados que comprovem possuir os requisitos mínimos de qualificação.',
  },
};

export const getModalidadeName = (id: number) => {
  return modalidadesMap[id as keyof typeof modalidadesMap]?.name || 'Desconhecido';
};

export const getModalidadeInfo = (id: number): ModalidadeInfo => {
  const defaultInfo: ModalidadeInfo = {
    name: 'Desconhecido',
    icon: modalidadesMap[0].icon,
    color: '#999999', // Default color for unknown modalidade
    description: 'Modalidade não identificada',
  };

  const modalidade = modalidadesMap[id as keyof typeof modalidadesMap];
  if (!modalidade) return defaultInfo;

  return {
    name: modalidade.name,
    icon: modalidade.icon,
    color: modalidade.color || '#1976d2', // Use a default color if not specified
    description: modalidade.description || 'Descrição não disponível',
  };
};

export const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  return text.substr(0, maxLength) + '...';
};

export const ESFERAS: Record<string, string> = {
  [Esfera.Federal]: 'Federal',
  [Esfera.Estadual]: 'Estadual',
  [Esfera.MunicipalDistrital]: 'Municipal/Distrital',
  [Esfera.AdministracaoIndireta]: 'Administração Indireta (autarquias, fundações, consórcios)',
  [Esfera.NaoClassificado]: 'Não classificado',
};

export const PORTAL_ORDER: Record<Portal, number> = {
  [Portal.ComprasGovernamentais]: 1,
  [Portal.PortalNacionalDeComprasPublicas]: 2,
  [Portal.PortalDeComprasPublicas]: 3,
  [Portal.BLLCompras]: 4,
  [Portal.BNCCompras]: 5,
  [Portal.BBMNET]: 6,
  [Portal.ComprasBR]: 7,
  [Portal.LicitaNET]: 8,
  [Portal.LicitarDigital]: 9,
  [Portal.Banrisul]: 10,
  [Portal.DOU]: 11,
  [Portal.LicitacoesEBancoDoBrasil]: 12,
  [Portal.BECSP]: 13,
  [Portal.PRODESP]: 14,
  [Portal.LCON]: 15,
  [Portal.DemaisPortais]: 99,
};

export const PORTAL_NAMES: { [key in Portal]: string } = {
  [Portal.ComprasGovernamentais]: 'ComprasNET',
  [Portal.PortalNacionalDeComprasPublicas]: 'Portal Nacional de Compras Públicas (PNCP)',
  [Portal.PortalDeComprasPublicas]: 'Portal de Compras Públicas (PCP)',
  [Portal.BLLCompras]: 'BLL Compras',
  [Portal.BNCCompras]: 'BNC Compras',
  [Portal.BBMNET]: 'BBMNET',
  [Portal.ComprasBR]: 'Compras BR',
  [Portal.LicitaNET]: 'LicitaNET',
  [Portal.LicitarDigital]: 'Licitar Digital',
  [Portal.Banrisul]: 'Banrisul',
  [Portal.DOU]: 'DOU',
  [Portal.LCON]: 'Licitacon',
  [Portal.LicitacoesEBancoDoBrasil]: 'Licitações-E / BB',
  [Portal.BECSP]: 'BEC-SP',
  [Portal.PRODESP]: 'ProDESP',
  [Portal.DemaisPortais]: 'Demais portais',
};

export const PORTAL_SHORT_NAMES: { [key in Portal]: string } = {
  [Portal.ComprasGovernamentais]: 'ComprasNET',
  [Portal.PortalNacionalDeComprasPublicas]: 'PNCP',
  [Portal.PortalDeComprasPublicas]: 'PCP',
  [Portal.BLLCompras]: 'BLL',
  [Portal.BNCCompras]: 'BNC',
  [Portal.BBMNET]: 'BBMNET',
  [Portal.ComprasBR]: 'ComprasBR',
  [Portal.LicitaNET]: 'LicitaNET',
  [Portal.LicitarDigital]: 'Licitar Digital',
  [Portal.Banrisul]: 'Banrisul',
  [Portal.DOU]: 'DOU',
  [Portal.LCON]: 'LCON',
  [Portal.LicitacoesEBancoDoBrasil]: 'L-E/BB',
  [Portal.BECSP]: 'BEC-SP',
  [Portal.PRODESP]: 'ProDESP',
  [Portal.DemaisPortais]: 'Outros',
};

// Add this type guard function
export function isKeyOfLicitacoesSearchParams(key: string): key is keyof LicitacoesSearchParams {
  return (
    key in
    {
      palavra_chave: true,
      uf: true,
      municipio_ibge: true,
      modalidade: true,
      data_abertura_inicio: true,
      data_abertura_fim: true,
      data_publicacao_inicio: true,
      data_publicacao_fim: true,
      id_portal: true,
      valor_min: true,
      valor_max: true,
      cnae: true,
      item: true,
      codigo_uasg: true,
      edital: true,
      esfera: true,
      match_exact: true,
      sortBy: true,
      palavras_excluidas: true,
      date_filter_type: true,
    }
  );
}

export const FIXED_KANBAN_COLUMNS: KanbanColumn[] = [
  {
    id: 'oportunidade',
    title: 'Oportunidade',
    position: 0,
    licitacoesIds: [],
    licitacoes: [],
  },
  {
    id: 'qualificacao',
    title: 'Qualificação',
    position: 1,
    licitacoesIds: [],
    licitacoes: [],
  },
  {
    id: 'disputa',
    title: 'Disputa',
    position: 2,
    licitacoesIds: [],
    licitacoes: [],
  },
  {
    id: 'classificacao',
    title: 'Classificação',
    position: 3,
    licitacoesIds: [],
    licitacoes: [],
  },
  {
    id: 'contrato',
    title: 'Contrato',
    position: 4,
    licitacoesIds: [],
    licitacoes: [],
  },
];

export const DEFAULT_BOARD_ID = 'default';
export const DEFAULT_BOARD_NAME = 'Meu Quadro de Licitações';

export const AI_CHAT_EXAMPLE_QUESTIONS = [
  'Quais documentos são necessários nesta licitação?',
  'Qual é o prazo para envio da proposta?',
  'Quais são os principais requisitos técnicos?',
  'Existe alguma exigência específica para participação?',
  'Precisa de seguro garantia? Quais?',
  'Quando será a sessão de abertura das propostas?',
  'Há possibilidade de visita técnica?',
  'Quais são as condições de pagamento?',
  'Existe previsão de subcontratação?',
  'Quais são os critérios de julgamento das propostas?',
];

export const BRAZILIAN_STOP_WORDS = [
  'a',
  'ainda',
  'alem',
  'ambas',
  'ambos',
  'antes',
  'ao',
  'aonde',
  'aos',
  'apos',
  'aquele',
  'aqueles',
  'as',
  'assim',
  'com',
  'como',
  'contra',
  'contudo',
  'cuja',
  'cujas',
  'cujo',
  'cujos',
  'da',
  'das',
  'de',
  'dela',
  'dele',
  'deles',
  'demais',
  'depois',
  'desde',
  'desta',
  'deste',
  'dispoe',
  'dispoem',
  'diversa',
  'diversas',
  'diversos',
  'do',
  'dos',
  'durante',
  'e',
  'ela',
  'elas',
  'ele',
  'eles',
  'em',
  'entao',
  'entre',
  'essa',
  'essas',
  'esse',
  'esses',
  'esta',
  'estas',
  'este',
  'estes',
  'ha',
  'isso',
  'isto',
  'logo',
  'mais',
  'mas',
  'mediante',
  'menos',
  'mesma',
  'mesmas',
  'mesmo',
  'mesmos',
  'na',
  'nas',
  'nao',
  'nem',
  'nesse',
  'neste',
  'nos',
  'o',
  'os',
  'ou',
  'outra',
  'outras',
  'outro',
  'outros',
  'pelas',
  'pelo',
  'pelos',
  'perante',
  'pois',
  'por',
  'porque',
  'portanto',
  'proprio',
  'propios',
  'quais',
  'qual',
  'qualquer',
  'quando',
  'quanto',
  'que',
  'quem',
  'quer',
  'se',
  'seja',
  'sem',
  'sendo',
  'seu',
  'seus',
  'sob',
  'sobre',
  'sua',
  'suas',
  'tal',
  'tambem',
  'teu',
  'teus',
  'toda',
  'todas',
  'todo',
  'todos',
  'tua',
  'tuas',
  'tudo',
  'um',
  'uma',
  'umas',
  'uns',
  'para',
  'por',
  'pra',
  'pro',
  'pela',
];

export const COMPANY_SIZES: Record<CompanySizeEnum, string> = {
  [CompanySizeEnum.MEI]: 'MICRO EMPREENDEDOR INDIVIDUAL',
  [CompanySizeEnum.MICRO]: 'MICRO EMPRESA',
  [CompanySizeEnum.SMALL]: 'EMPRESA DE PEQUENO PORTE',
  [CompanySizeEnum.MEDIUM]: 'EMPRESA DE MÉDIO PORTE',
  [CompanySizeEnum.LARGE]: 'EMPRESA DE GRANDE PORTE',
};
