import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';

interface ItemsPerPageSelectProps {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}

const ItemsPerPageSelect: React.FC<ItemsPerPageSelectProps> = ({ value, onChange, disabled }) => {
  const handleChange = (event: SelectChangeEvent<number>) => {
    onChange(event.target.value as number);
  };

  return (
    <FormControl size="small" sx={{ minWidth: 120 }}>
      <Select
        value={value}
        onChange={handleChange}
        disabled={disabled}
        variant="outlined"
        sx={{ height: 32 }}
      >
        <MenuItem value={10}>10 por página</MenuItem>
        <MenuItem value={20}>20 por página</MenuItem>
        <MenuItem value={50}>50 por página</MenuItem>
        <MenuItem value={100}>100 por página</MenuItem>
      </Select>
    </FormControl>
  );
};

export default ItemsPerPageSelect;
