import { getModalidadeInfo, getModalidadeName } from '@constants';
import Api from '@services/api';
import {
  Licitacao,
  LicitacaoSortOption,
  LicitacoesSearchParams,
  LicitacoesSearchResponse,
  ModalidadeInfo,
} from '@types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useFavoritesStore } from './favoritesStore';
import { useFollowingStore } from './followingStore';
import { useRecommendedStore } from './recommendedStore';

interface LicitacoesState {
  licitacoes: Licitacao[];
  isLoading: boolean;
  error: string | null;
  totalLicitacoes: number;
  fetchLicitacoes: (params: LicitacoesSearchParams) => Promise<LicitacoesSearchResponse | null>;
  getModalidadeName: (id: number) => string;
  getModalidadeInfo: (id: number) => ModalidadeInfo;
  getLicitacaoById: (id: string) => Licitacao | undefined;
  currentParams: LicitacoesSearchParams | null;
  cities: { [key: string]: { label: string; value: string }[] };
  fetchCitiesByState: (state: string) => Promise<void>;
  fetchLicitacaoDetails: (id: string) => Promise<Licitacao | null>;
  isLoadingDetails: boolean;
  resetStore: () => void;
  lastSearchParams: LicitacoesSearchParams | null;
  resetLastSearchParams: () => void;
  isFavorite: (licitacaoId: string) => boolean;
  sortOption: LicitacaoSortOption;
  setSortOption: (option: LicitacaoSortOption) => void;
  itemsPerPage: number;
  totalPages: number;
  setItemsPerPage: (perPage: number) => void;
  viewFormat: 'list' | 'column';
  setViewFormat: (format: 'list' | 'column') => void;
}

export const useLicitacoesStore = create<LicitacoesState>()(
  persist(
    (set, get) => ({
      licitacoes: [],
      isLoading: false,
      error: null,
      totalLicitacoes: 0,
      sortOption: LicitacaoSortOption.RELEVANCE,
      lastSearchParams: null,
      itemsPerPage: 20,
      totalPages: 0,
      viewFormat: 'list',
      fetchLicitacoes: async (params: LicitacoesSearchParams) => {
        set({ isLoading: true, error: null });
        try {
          const fetchParams = {
            ...params,
            licitacoesPorPagina: get().itemsPerPage,
            sortBy: get().sortOption,
          };
          const data = await Api.getLicitacoes(fetchParams);
          set({
            licitacoes: data.licitacoes,
            isLoading: false,
            totalPages: data.paginas,
            totalLicitacoes: data.totalLicitacoes,
            currentParams: fetchParams,
            lastSearchParams: fetchParams,
          });
          return data;
        } catch (error) {
          console.error('Error fetching licitacoes:', error);
          set({
            isLoading: false,
            error: 'Erro ao carregar licitações',
            totalPages: 0,
          });
          return null;
        }
      },
      getModalidadeName,
      getModalidadeInfo,
      getLicitacaoById: (id: string) => {
        const { licitacoes } = get();
        const licitacao = licitacoes.find((l) => l.id === id);
        if (licitacao) return licitacao;

        const recommendedStore = useRecommendedStore.getState();
        const recommendedLicitacao = recommendedStore.recommendedList.find((l) => l.id === id);
        if (recommendedLicitacao) return recommendedLicitacao;

        const favoritesStore = useFavoritesStore.getState();
        const favoriteLicitacao = favoritesStore.favoriteLicitacoes.find((l) => l.id === id);
        if (favoriteLicitacao) return favoriteLicitacao;

        const followingStore = useFollowingStore.getState();
        const followingLicitacao = followingStore.followingLicitacoes.find((l) => l.id === id);
        if (followingLicitacao) return followingLicitacao;

        return undefined;
      },
      currentParams: null,
      cities: {},
      fetchCitiesByState: async (state: string) => {
        try {
          const citiesData = Api.getCitiesByStateUF(state);
          const formattedCities = citiesData.map((city) => ({
            label: city.nome_municipio,
            value: city.codigo_ibge,
          })) as any;
          set((prevState) => ({
            cities: {
              ...prevState.cities,
              [state]: formattedCities,
            },
          }));
        } catch (error) {
          console.error('Error fetching cities:', error);
        }
      },
      fetchLicitacaoDetails: async (id: string, refresh = false) => {
        const state = get();
        if (!refresh) {
          const cachedLicitacao = state.getLicitacaoById(id);
          if (cachedLicitacao) {
            return cachedLicitacao;
          }
        }

        set({ isLoadingDetails: true });
        try {
          const data = await Api.getLicitacaoDetails(id);
          set((state) => ({
            licitacoes: state.licitacoes.map((l) => (l.id === id ? { ...l, ...data } : l)),
          }));
          return data;
        } catch (error) {
          console.error('Error fetching licitacao details:', error);
          return null;
        } finally {
          set({ isLoadingDetails: false });
        }
      },
      isLoadingDetails: false,
      resetStore: () => {
        set({
          licitacoes: [],
          isLoading: false,
          error: null,
          totalPages: 0,
          totalLicitacoes: 0,
          currentParams: null,
          cities: {},
          itemsPerPage: 20,
        });
      },
      isFavorite: (licitacaoId: string) => {
        return useFavoritesStore.getState().isFavorite(licitacaoId);
      },
      setSortOption: (option: LicitacaoSortOption) => set({ sortOption: option }),
      setItemsPerPage: (perPage: number) => {
        set({ itemsPerPage: perPage });
        const currentParams = get().currentParams;
        if (currentParams) {
          get().fetchLicitacoes({ ...currentParams, pagina: 1 });
        }
      },
      resetLastSearchParams: () => set({ lastSearchParams: null }),
      setViewFormat: (format: 'list' | 'column') => set({ viewFormat: format }),
    }),
    {
      name: 'licitacoes-storage',
      partialize: (state: LicitacoesState) => ({
        itemsPerPage: state.itemsPerPage,
        viewFormat: state.viewFormat,
      }),
    },
  ),
);
