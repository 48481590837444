import { useProfileStore } from '@store/profileStore';
import { isBefore, parseISO } from 'date-fns';
import { useEffect } from 'react';

export const useTrialCheck = (interval = 60000) => {
  const { userData, loadUserData } = useProfileStore();

  useEffect(() => {
    if (!userData?.gracePeriodEnd || userData.subscriptionStatus !== 'trialing') {
      return;
    }

    const checker = setInterval(() => {
      if (isBefore(parseISO(userData.gracePeriodEnd!), new Date())) {
        loadUserData();
      }
    }, interval);

    return () => clearInterval(checker);
  }, [userData?.gracePeriodEnd, userData?.subscriptionStatus]);
};
