import { Alert, AlertColor, Snackbar } from '@mui/material';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface NotificationContextData {
  showNotification: (message: string, severity?: AlertColor, duration?: number) => void;
  hideNotification: () => void;
}

interface NotificationState {
  message: string;
  severity: AlertColor;
  open: boolean;
  duration: number;
}

const DEFAULT_DURATION = 4000;

const NotificationContext = createContext<NotificationContextData>({} as NotificationContextData);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState<NotificationState>({
    message: '',
    severity: 'info',
    open: false,
    duration: DEFAULT_DURATION,
  });

  const hideNotification = useCallback(() => {
    setState((prev) => ({
      ...prev,
      open: false,
    }));
  }, []);

  const showNotification = useCallback(
    (message: string, severity: AlertColor = 'info', duration: number = DEFAULT_DURATION) => {
      setState({
        message,
        severity,
        open: true,
        duration,
      });
    },
    [],
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (state.open) {
      timeoutId = setTimeout(() => {
        hideNotification();
      }, state.duration);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [state.open, state.duration, hideNotification]);

  return (
    <NotificationContext.Provider value={{ showNotification, hideNotification }}>
      {children}
      <Snackbar
        open={state.open}
        autoHideDuration={state.duration}
        onClose={hideNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={hideNotification}
          severity={state.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {state.message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};
