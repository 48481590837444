import { Card as MuiCard, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const StyledStack = styled(Stack)({
  padding: 20,
  minHeight: '100vh',
  justifyContent: 'center',
});

const StyledCard = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    theme.palette.mode === 'dark' ? '0 0 20px rgba(0,0,0,0.4)' : '0 0 20px rgba(0,0,0,0.1)',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'hsla(220, 25%, 10%, 0.7)' // gray[900] with opacity
      : 'rgba(255, 255, 255, 0.7)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
}));

interface PageContainerProps {
  children: React.ReactNode;
  maxWidth?: string;
}

const PageContainer: React.FC<PageContainerProps> = ({ children, maxWidth = '450px' }) => {
  return (
    <StyledStack>
      <StyledCard sx={{ maxWidth: { sm: maxWidth } }}>{children}</StyledCard>
    </StyledStack>
  );
};

export default PageContainer;
