import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { Box, Button, Paper, Typography, useTheme } from '@mui/material';
import { useProfileStore } from '@store/profileStore';
import { isBefore, parseISO } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type ReminderType = 'trial' | 'past_due' | 'trial_expired';

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const SubscriptionReminder: React.FC = () => {
  const { userData, loadUserData } = useProfileStore();
  const navigate = useNavigate();
  const theme = useTheme();
  const [timeLeft, setTimeLeft] = useState<TimeLeft | null>(null);

  useEffect(() => {
    if (!userData?.gracePeriodEnd || userData.subscriptionStatus !== 'trialing') {
      return;
    }

    const now = new Date();
    const gracePeriodEnd = parseISO(userData.gracePeriodEnd);

    if (isBefore(gracePeriodEnd, now)) {
      setTimeLeft(null);
      loadUserData();
      return;
    }

    const updateTimeLeft = () => {
      const now = new Date();
      const end = parseISO(userData.gracePeriodEnd!);
      const diff = end.getTime() - now.getTime();

      if (diff <= 0) {
        setTimeLeft(null);
        loadUserData();
        return;
      }

      setTimeLeft({
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((diff / 1000 / 60) % 60),
        seconds: Math.floor((diff / 1000) % 60),
      });
    };

    updateTimeLeft();
    const interval = setInterval(updateTimeLeft, 1000);

    return () => clearInterval(interval);
  }, [userData?.gracePeriodEnd, userData?.subscriptionStatus, loadUserData]);

  const reminderType = useMemo((): ReminderType => {
    if (userData?.subscriptionStatus === 'past_due') return 'past_due';
    if (userData?.subscriptionStatus === 'trialing' && userData.gracePeriodEnd) {
      const now = new Date();
      const gracePeriodEnd = parseISO(userData.gracePeriodEnd);
      return isBefore(gracePeriodEnd, now) ? 'trial_expired' : 'trial';
    }
    return 'past_due';
  }, [userData?.subscriptionStatus, userData?.gracePeriodEnd]);

  const getTimeLeftString = () => {
    if (!timeLeft) return 'alguns instantes';
    if (timeLeft.days > 0) return `${timeLeft.days} dias`;
    if (timeLeft.hours > 0) return `${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`;
    return `${timeLeft.minutes}m ${timeLeft.seconds}s`;
  };

  const messages = {
    past_due:
      'Seu pagamento está atrasado. Por favor, atualize seu método de pagamento para evitar a interrupção do serviço.',
    trial: `Você está no período de avaliação gratuita. Restam ${getTimeLeftString()} para experimentar todas as funcionalidades.`,
    trial_expired:
      'Seu período de avaliação gratuita expirou. Assine agora para continuar usando todas as funcionalidades.',
  };

  const buttonText = {
    past_due: 'Atualizar Pagamento',
    trial: 'Assinar Agora',
    trial_expired: 'Assinar Agora',
  };

  const colors = {
    past_due: theme.palette.warning.main,
    trial: theme.palette.info.main,
    trial_expired: theme.palette.error.main,
  };

  const handleClick = () => {
    navigate('/configuracoes/pagamento');
  };

  if (!userData || (reminderType === 'past_due' && userData.subscriptionStatus !== 'past_due')) {
    return null;
  }

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        mb: 3,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${colors[reminderType]}`,
        borderRadius: 1,
      }}
    >
      <Box display="flex" alignItems="center" gap={2}>
        <InfoIcon sx={{ color: colors[reminderType] }} />
        <Box flex={1}>
          <Typography variant="body1">{messages[reminderType]}</Typography>
        </Box>
        <Button
          variant="contained"
          color={
            reminderType === 'trial' ? 'primary' : reminderType === 'past_due' ? 'warning' : 'error'
          }
          onClick={handleClick}
          size="small"
        >
          {buttonText[reminderType]}
        </Button>
      </Box>
    </Paper>
  );
};

export default SubscriptionReminder;
