import ChipInput from '@components/Input/ChipInput';
import CnaeSelector from '@components/Input/CnaeSelector';
import CurrencyInput from '@components/Input/CurrencyInput';
import Keywords from '@components/Input/Keywords';
import MultiSelect from '@components/Input/MultiSelect';
import { brazilianStates, ESFERAS, modalidadesMap, PORTAL_NAMES } from '@constants';
import { useAnalytics } from '@hooks/useAnalytics';
import { Clear, ExpandLess, ExpandMore, Search } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Api from '@services/api';
import { Esfera, LicitacoesSearchParams, Portal, UASG } from '@types';
import debounce from '@utils/debounce';
import { createPortalOptions } from '@utils/index';
import { addDays, endOfDay, format, isValid, parse, startOfDay } from 'date-fns';
import React, { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';

type DateFilterType = 'abertura' | 'publicacao';

interface SearchFiltersProps {
  handleSearch: (params: LicitacoesSearchParams) => void;
  clearAllFilters: () => void;
  isLoading: boolean;
  className?: string;
  initialValues?: Partial<LicitacoesSearchParams>;
}

// Move formatting functions outside component
const formatDateForDisplay = (date: Date | null) => {
  if (!date || !isValid(date)) return '';
  return format(date, 'dd-MM-yyyy');
};

const formatDateForApi = (date: Date | null) => {
  if (!date || !isValid(date)) return undefined;
  return format(date, 'yyyy-MM-dd');
};

const parseApiDate = (dateStr: string | undefined) => {
  if (!dateStr) return null;
  try {
    const parsedDate = parse(dateStr, 'yyyy-MM-dd', new Date());
    return isValid(parsedDate) ? parsedDate : null;
  } catch {
    return null;
  }
};

const SearchFilters: React.FC<SearchFiltersProps> = ({
  handleSearch,
  clearAllFilters,
  isLoading,
  initialValues,
  className,
}) => {
  const theme = useTheme();
  const { trackEvent } = useAnalytics();

  const [isExpanded, setIsExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string[]>([]);
  const [uf, setUf] = useState<string[]>([]);
  const [selectedCity, setSelectedCity] = useState<string[]>([]);
  const [modalidade, setModalidade] = useState<string[]>([]);
  const [selectedPortal, setSelectedPortal] = useState<string[]>([]);
  const [selectedEsfera, setSelectedEsfera] = useState<string[]>([]);
  const [cities, setCities] = useState<{ label: string; value: string }[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(() => {
    if (initialValues?.data_abertura_inicio) {
      return parseApiDate(initialValues.data_abertura_inicio);
    }
    return startOfDay(new Date());
  });
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [selectedCnaes, setSelectedCnaes] = useState<string[]>([]);
  const [codigoUasg, setCodigoUasg] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [item, setItem] = useState('');
  const [matchExact, setMatchExact] = useState(false);
  const [edital, setEdital] = useState('');
  const [currentInputValue, setCurrentInputValue] = useState('');

  const [uasgOptions, setUasgOptions] = useState<{ label: string; value: string }[]>([]);
  const [negativeKeywords, setNegativeKeywords] = useState<string[]>([]);
  const [dateFilterType, setDateFilterType] = useState<DateFilterType>('abertura');

  // Optimize city fetching to handle multiple states
  const fetchCities = useCallback(async (states: string[]) => {
    try {
      const citiesPromises = states.map((state) => Api.getCitiesByStateUF(state));
      const citiesDataArrays = await Promise.all(citiesPromises);

      // Flatten and merge cities from all states
      const mergedCities = citiesDataArrays.flat().map((city) => ({
        label: city.nome_municipio,
        value: city.codigo_ibge.toString(),
      }));

      // Sort cities by name and remove duplicates
      const uniqueCities = Array.from(
        new Map(mergedCities.map((city) => [city.value, city])).values(),
      ).sort((a, b) => a.label.localeCompare(b.label));

      setCities(uniqueCities);
    } catch (error) {
      console.error('Error fetching cities:', error);
      setCities([]);
    }
  }, []);

  // Remove cnaesList from memoizedOptions
  const memoizedOptions = useMemo(
    () => ({
      esferaOptions: Object.entries(ESFERAS).map(([key, value]) => ({
        value: key,
        label: value,
      })),
      modalidadesOptions: Object.entries(modalidadesMap).map(([value, { name }]) => ({
        value,
        label: name,
      })),
      portalOptions: createPortalOptions(),
      stateOptions: brazilianStates.map((state) => ({
        value: state.value,
        label: state.label,
      })),
    }),
    [],
  );

  useEffect(() => {
    if (initialValues) {
      setSelectedCnaes(initialValues.cnae || []);
      setSearchQuery(
        Array.isArray(initialValues.palavra_chave)
          ? initialValues.palavra_chave
          : initialValues.palavra_chave
            ? [initialValues.palavra_chave]
            : [],
      );
      setNegativeKeywords(
        Array.isArray(initialValues.palavras_excluidas)
          ? initialValues.palavras_excluidas
          : initialValues.palavras_excluidas
            ? [initialValues.palavras_excluidas]
            : [],
      );
      setUf(initialValues.uf || []);
      setSelectedCity(initialValues.municipio_ibge || []);
      setModalidade(initialValues.modalidade || []);
      setStartDate(
        initialValues.data_abertura_inicio ? new Date(initialValues.data_abertura_inicio) : null,
      );
      setEndDate(
        initialValues.data_abertura_fim ? new Date(initialValues.data_abertura_fim) : null,
      );
      setSelectedPortal(initialValues.id_portal?.map((id) => id.toString()) || []);
      setSelectedEsfera(initialValues.esfera?.map(String) || []);
      setMinValue(initialValues.valor_min || '');
      setMaxValue(initialValues.valor_max || '');
      setCodigoUasg(
        initialValues.codigo_uasg && initialValues.codigo_uasg.length > 0
          ? { label: '', value: initialValues.codigo_uasg[0] }
          : null,
      );
      setItem(initialValues.item || '');
      setEdital(initialValues.edital || '');
      setMatchExact(initialValues.match_exact === true);

      // Fetch cities if a state is selected
      if (initialValues.uf && initialValues.uf.length > 0) {
        try {
          const citiesData = Api.getCitiesByStateUF(initialValues.uf[0]);
          setCities(
            citiesData.map((city) => ({
              label: city.nome_municipio,
              value: city.codigo_ibge.toString(),
            })),
          );
        } catch (citiesError) {
          console.error('Error fetching cities:', citiesError);
          setCities([]);
        }
      }
    }
  }, [initialValues]);

  // Keep the params memo as it prevents unnecessary recalculations
  const params = useMemo(() => {
    const baseParams: LicitacoesSearchParams = {
      palavra_chave: searchQuery,
      palavras_excluidas: negativeKeywords,
      uf,
      municipio_ibge: selectedCity,
      modalidade,
      id_portal: selectedPortal as Portal[],
      esfera: selectedEsfera as Esfera[],
      valor_min: minValue,
      valor_max: maxValue,
      cnae: selectedCnaes,
      codigo_uasg: codigoUasg ? [codigoUasg.value] : undefined,
      item,
      edital,
      match_exact: matchExact,
    };

    if (dateFilterType === 'publicacao') {
      baseParams.data_publicacao_inicio = formatDateForApi(startDate);
      baseParams.data_publicacao_fim = formatDateForApi(endDate);
    } else {
      baseParams.data_abertura_inicio = formatDateForApi(startDate);
      baseParams.data_abertura_fim = formatDateForApi(endDate);
    }

    return baseParams;
  }, [
    searchQuery,
    negativeKeywords,
    uf,
    selectedCity,
    modalidade,
    startDate,
    endDate,
    selectedPortal,
    minValue,
    maxValue,
    selectedCnaes,
    codigoUasg,
    item,
    matchExact,
    edital,
    dateFilterType,
  ]);

  // Single useEffect for both initial and subsequent state changes
  useEffect(() => {
    if (initialValues?.uf && (initialValues?.uf?.length > 0 || uf.length > 0)) {
      const statesToFetch = initialValues?.uf?.length ? initialValues.uf : uf;
      fetchCities(statesToFetch);
    } else {
      setCities([]);
    }
  }, [uf, initialValues?.uf, fetchCities]);

  const handleStateChange = useCallback((value: string[]) => {
    setUf(value);
    setSelectedCity([]);
  }, []);

  const handleClearAllFilters = () => {
    setSearchQuery([]);
    setUf([]);
    setSelectedCity([]);
    setModalidade([]);
    setStartDate(null);
    setEndDate(null);
    setCities([]);
    setSelectedPortal([]);
    setMinValue('');
    setMaxValue('');
    setSelectedCnaes([]);
    setCodigoUasg(null);
    setItem('');
    setMatchExact(false);
    setEdital('');
    setNegativeKeywords([]);
    clearAllFilters();
  };

  const handleSearchPress = () => {
    trackEvent('search_executed', {
      keyword_count: searchQuery.length,
      negative_keyword_count: negativeKeywords.length,
      active_filter_count: activeFilters.length,
      has_date_range: Boolean(startDate && endDate),
      has_value_range: Boolean(minValue && maxValue),
    });
    const updatedSearchQuery = [...searchQuery];
    if (currentInputValue.trim() && !updatedSearchQuery.includes(currentInputValue.trim())) {
      updatedSearchQuery.push(currentInputValue.trim());
    }
    setSearchQuery(updatedSearchQuery);
    handleSearch({
      ...params,
      palavra_chave: updatedSearchQuery,
    });
    setIsExpanded(false);
  };
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSearchPress();
    }
  };
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Keep the activeFilters memo as it's used in multiple places
  const activeFilters = useMemo(() => {
    const filters = [];
    if (searchQuery.length > 0)
      filters.push({
        key: 'palavra_chave',
        value: searchQuery,
        label: `Busca: ${searchQuery.join(', ')}`,
      });
    if (negativeKeywords.length > 0)
      filters.push({
        key: 'palavras_excluidas',
        value: negativeKeywords,
        label: `Excluir: ${negativeKeywords.join(', ')}`,
      });
    if (uf.length > 0)
      filters.push({
        key: 'uf',
        value: uf,

        // label: `Estado: ${uf.map((u) => brazilianStates.find((state) => state.value === u)?.label).join(', ')}`,
        label: `UF: ${uf.join(', ')}`,
      });
    if (selectedCity.length > 0)
      filters.push({
        key: 'municipio_ibge',
        value: selectedCity,
        label: `Cidade: ${selectedCity.map((c) => Api.getCitiesByMunicipioIBGE(parseInt(c))?.nome_municipio).join(', ')}`,
      });
    if (modalidade.length > 0)
      filters.push({
        key: 'modalidade',
        value: modalidade,
        label: `Modalidade: ${modalidade.map((m) => modalidadesMap[parseInt(m)]?.name).join(', ')}`,
      });
    if (startDate)
      filters.push({
        key: dateFilterType === 'abertura' ? 'data_abertura_inicio' : 'data_publicacao_inicio',
        value: formatDateForApi(startDate),
        label: `Data ${dateFilterType === 'abertura' ? 'Abertura' : 'Publicação'} Início: ${formatDateForDisplay(startDate)}`,
      });
    if (endDate)
      filters.push({
        key: dateFilterType === 'abertura' ? 'data_abertura_fim' : 'data_publicacao_fim',
        value: formatDateForApi(endDate),
        label: `Data ${dateFilterType === 'abertura' ? 'Abertura' : 'Publicação'} Fim: ${formatDateForDisplay(endDate)}`,
      });
    if (selectedPortal.length > 0)
      filters.push({
        key: 'id_portal',
        value: selectedPortal,
        label: `Portal: ${selectedPortal.map((p) => PORTAL_NAMES[p as Portal]).join(', ')}`,
      });
    if (selectedEsfera.length > 0)
      filters.push({
        key: 'esfera',
        value: selectedEsfera,
        label: `Esfera: ${selectedEsfera.map((e) => ESFERAS[e as Esfera]).join(', ')}`,
      });
    if (minValue)
      filters.push({
        key: 'valor_min',
        value: minValue,
        label: `Valor Mín: ${minValue}`,
      });
    if (maxValue)
      filters.push({
        key: 'valor_max',
        value: maxValue,
        label: `Valor Máx: ${maxValue}`,
      });
    if (selectedCnaes.length > 0)
      filters.push({
        key: 'cnae',
        value: selectedCnaes,
        label: `CNAE: ${selectedCnaes.join(', ')}`,
      });
    if (codigoUasg)
      filters.push({
        key: 'codigo_uasg',
        value: codigoUasg?.value || '',
        label: `UASG: ${codigoUasg?.value}`,
      });
    if (matchExact)
      filters.push({
        key: 'match_exact',
        value: matchExact,
        label: 'Busca exata',
      });
    if (item) filters.push({ key: 'item', value: item, label: `Item: ${item}` });
    if (edital) filters.push({ key: 'edital', value: edital, label: `Edital: ${edital}` });
    return filters;
  }, [
    searchQuery,
    negativeKeywords,
    uf,
    selectedCity,
    modalidade,
    startDate,
    endDate,
    selectedPortal,
    minValue,
    maxValue,
    selectedCnaes,
    codigoUasg,
    item,
    matchExact,
    edital,
  ]);

  const handleRemoveFilter = (key: string) => {
    trackEvent('search_filter_removed', {
      filter_key: key,
      remaining_filter_count: activeFilters.length - 1,
    });
    const updatedParams = { ...params };
    switch (key) {
      case 'palavra_chave':
        setSearchQuery([]);
        updatedParams.palavra_chave = [];
        break;
      case 'uf':
        setUf([]);
        updatedParams.uf = [];
        break;
      case 'municipio_ibge':
        setSelectedCity([]);
        updatedParams.municipio_ibge = [];
        break;
      case 'modalidade':
        setModalidade([]);
        updatedParams.modalidade = [];
        break;
      case 'data_abertura_inicio':
        setStartDate(null);
        updatedParams.data_abertura_inicio = undefined;
        break;
      case 'data_abertura_fim':
        setEndDate(null);
        updatedParams.data_abertura_fim = undefined;
        break;
      case 'id_portal':
        setSelectedPortal([]);
        updatedParams.id_portal = [];
        break;
      case 'esfera':
        setSelectedEsfera([]);
        updatedParams.esfera = [];
        break;
      case 'valor_min':
        setMinValue('');
        updatedParams.valor_min = '';
        break;
      case 'valor_max':
        setMaxValue('');
        updatedParams.valor_max = '';
        break;
      case 'cnae':
        setSelectedCnaes([]);
        updatedParams.cnae = [];
        break;
      case 'codigo_uasg':
        setCodigoUasg(null);
        updatedParams.codigo_uasg = [];
        break;
      case 'item':
        setItem('');
        updatedParams.item = '';
        break;
      case 'edital':
        setEdital('');
        updatedParams.edital = '';
        break;
      case 'match_exact':
        setMatchExact(false);
        updatedParams.match_exact = false;
        break;
      case 'palavras_excluidas':
        setNegativeKeywords([]);
        break;
    }
    handleSearch(updatedParams);
  };

  const searchUASG = useCallback(async (query: string) => {
    if (query.length >= 3) {
      const options: UASG[] = await Api.searchUASG(query);
      const uasgOptions = options.map((option) => ({
        label: `${option.id} - ${option.nome}`,
        value: option.id.toString(),
      }));
      setUasgOptions(uasgOptions);
    } else {
      setUasgOptions([]);
    }
  }, []);

  const debouncedSearchUASG = useMemo(() => debounce(searchUASG, 300), [searchUASG]);

  useEffect(() => {
    return () => {
      debouncedSearchUASG.cancel();
    };
  }, [debouncedSearchUASG]);

  const handleChipRemove = (removedChip: string) => {
    const updatedParams = { ...params };
    updatedParams.palavra_chave = searchQuery.filter((chip) => chip !== removedChip);
    handleSearch(updatedParams);
  };

  const handleMinValueChange = (value: string) => {
    setMinValue(value);
  };

  const handleMaxValueChange = (value: string) => {
    setMaxValue(value);
  };

  const handleQuickDateSelect = (days: number) => {
    trackEvent('quick_date_selected', {
      days_range: days,
      has_existing_filters: activeFilters.length > 0,
    });
    const today = new Date();
    setStartDate(startOfDay(today));
    setEndDate(endOfDay(addDays(today, days)));
  };

  const handleClearStartDate = () => {
    setStartDate(null);
  };

  const handleClearEndDate = () => {
    setEndDate(null);
  };

  // Add this common style object
  const commonInputStyle = useMemo(
    () => ({
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '&:hover fieldset': {
          borderColor: theme.palette.primary.light,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.dark,
        },
      },
    }),
    [theme],
  );

  return (
    <Paper
      elevation={3}
      className={`search-filters ${isExpanded ? 'search-filters-expanded' : ''}`}
      sx={{
        p: 2,
        mb: 2,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 1,
        boxShadow: theme.shadows[3],
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <ChipInput
              className={`search-box ${className}`}
              value={searchQuery}
              onChangeValue={setSearchQuery}
              onInputChange={setCurrentInputValue}
              onChipRemove={handleChipRemove}
              onEmptyEnter={() => handleSearchPress()}
              placeholder={
                isExpanded
                  ? 'Digite aqui palavra(s) chave e pressione ENTER para adicionar'
                  : 'Buscar licitações'
              }
              onFocus={() => setIsExpanded(true)}
              sx={{
                mr: 1,
                flex: 1,
                ...commonInputStyle,
              }}
            />
            {searchQuery.length > 0 && (
              <>
                <IconButton onClick={() => setSearchQuery([])} size="small">
                  <Clear />
                </IconButton>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={matchExact}
                      onChange={(e) => setMatchExact(e.target.checked)}
                      color="primary"
                      size="small"
                    />
                  }
                  label="Busca exata"
                  sx={{ mx: 1 }}
                />
              </>
            )}
          </Box>
          <Button
            variant="contained"
            onClick={handleSearchPress}
            disabled={isLoading}
            sx={{
              ml: 1,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            {isLoading ? <CircularProgress size={24} /> : <Search />}
          </Button>
          <IconButton
            className="search-expand-button"
            onClick={toggleExpand}
            size="small"
            sx={{ ml: 1 }}
          >
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        {activeFilters.length > 0 && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1, width: '100%' }}>
            {activeFilters.map((filter) => (
              <Chip
                key={filter.key}
                label={filter.label}
                onDelete={() => handleRemoveFilter(filter.key)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Box>
        )}
      </Box>
      <Collapse in={isExpanded} sx={{ width: '100%' }}>
        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <FormControl fullWidth>
              <CnaeSelector value={selectedCnaes} onChange={setSelectedCnaes} label="CNAE" />
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <MultiSelect<string>
              label="Estados"
              options={memoizedOptions.stateOptions}
              value={uf}
              showValueInChip={true}
              onChange={(value) => {
                setUf(value);
                handleStateChange(value);
              }}
              sx={{ mr: 1, flex: 1 }}
            />
            <MultiSelect<string>
              label="Cidades"
              options={cities}
              value={selectedCity}
              onChange={(value) => setSelectedCity(value)}
              disabled={uf.length === 0}
              sx={{ flex: 1 }}
            />
          </Box>

          <Box sx={{ display: 'flex', mb: 2, gap: 1 }}>
            <Box sx={{ width: '33%' }}>
              <MultiSelect<number>
                label="Portais"
                options={memoizedOptions.portalOptions}
                value={selectedPortal.map((v) => parseInt(v))}
                onChange={(value) => setSelectedPortal(value.map((v) => v.toString()))}
                sx={{ width: '100%' }}
              />
            </Box>
            <Box sx={{ width: '33%' }}>
              <MultiSelect<string>
                label="Modalidades"
                options={memoizedOptions.modalidadesOptions}
                value={modalidade}
                onChange={(value) => setModalidade(value)}
                sx={{ width: '100%' }}
              />
            </Box>
            <Box sx={{ width: '33%' }}>
              <FormControl sx={{ width: '100%' }}>
                <Autocomplete
                  options={uasgOptions}
                  value={codigoUasg}
                  onChange={(event, newValue) =>
                    setCodigoUasg(newValue as { label: string; value: string })
                  }
                  onInputChange={(event, newInputValue) => {
                    debouncedSearchUASG(newInputValue);
                  }}
                  noOptionsText="Digite o código ou nome da UASG"
                  renderInput={(params) => (
                    <TextField {...params} label="Código UASG" sx={commonInputStyle} />
                  )}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip label={option.value} {...getTagProps({ index })} key={option.value} />
                    ))
                  }
                />
              </FormControl>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', mb: 2, gap: 1 }}>
            <Box sx={{ width: '33%' }}>
              <MultiSelect<string>
                label="Esfera"
                options={memoizedOptions.esferaOptions}
                value={selectedEsfera}
                onChange={(value) => setSelectedEsfera(value)}
                sx={{ width: '100%' }}
              />
            </Box>
            <Box sx={{ width: '33%' }}>
              <CurrencyInput
                label="Valor Mínimo"
                value={minValue}
                onChange={handleMinValueChange}
                onKeyDown={handleKeyDown}
                sx={{
                  width: '100%',
                  ...commonInputStyle,
                }}
              />
            </Box>
            <Box sx={{ width: '33%' }}>
              <CurrencyInput
                label="Valor Máximo"
                value={maxValue}
                onChange={handleMaxValueChange}
                onKeyDown={handleKeyDown}
                sx={{
                  width: '100%',
                  ...commonInputStyle,
                }}
              />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', mb: 2 }}>
            <Keywords
              keywords={negativeKeywords}
              onChange={setNegativeKeywords}
              placeholder="Digite palavras para excluir e pressione ENTER"
              sx={{
                width: '60%',
                mr: 1,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.error.main,
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.error.light,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.error.dark,
                  },
                },
              }}
            />
            <TextField
              label="Número do Edital"
              value={edital}
              onChange={(e) => setEdital(e.target.value)}
              onKeyDown={handleKeyDown}
              sx={{
                width: '40%',
                ...commonInputStyle,
              }}
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <FormControl size="small" sx={{ minWidth: 200, mr: 2 }}>
                <Select
                  value={dateFilterType}
                  onChange={(e) => setDateFilterType(e.target.value as DateFilterType)}
                >
                  <MenuItem value="abertura">Data de Abertura</MenuItem>
                  <MenuItem value="publicacao">Data de Publicação</MenuItem>
                </Select>
              </FormControl>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                <Chip
                  label="Hoje"
                  onClick={() => handleQuickDateSelect(1)}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
                <Chip
                  label="Próximos 3 dias"
                  onClick={() => handleQuickDateSelect(3)}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
                <Chip
                  label="Próximos 7 dias"
                  onClick={() => handleQuickDateSelect(7)}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
                <Chip
                  label="Próximos 15 dias"
                  onClick={() => handleQuickDateSelect(15)}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
                <Chip
                  label="Próximos 30 dias"
                  onClick={() => handleQuickDateSelect(30)}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', mr: 1 }}>
                <DatePicker
                  label={`Data de ${dateFilterType === 'abertura' ? 'abertura' : 'publicação'} mínima`}
                  value={startDate}
                  onChange={(date: Date | null) => setStartDate(date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      sx: commonInputStyle,
                    },
                  }}
                />
                {startDate && (
                  <IconButton onClick={handleClearStartDate} size="small" sx={{ ml: 1 }}>
                    <Clear />
                  </IconButton>
                )}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', ml: 1 }}>
                <DatePicker
                  label={`Data de ${dateFilterType === 'abertura' ? 'abertura' : 'publicação'} máxima`}
                  value={endDate}
                  onChange={(date: Date | null) => setEndDate(date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      sx: commonInputStyle,
                    },
                  }}
                />
                {endDate && (
                  <IconButton onClick={handleClearEndDate} size="small" sx={{ ml: 1 }}>
                    <Clear />
                  </IconButton>
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="outlined"
              onClick={handleClearAllFilters}
              sx={{
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                  borderColor: theme.palette.primary.main,
                },
              }}
            >
              Limpar Filtros
            </Button>
            <Button
              variant="contained"
              onClick={handleSearchPress}
              disabled={isLoading}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
                '&:disabled': {
                  backgroundColor: theme.palette.action.disabledBackground,
                  color: theme.palette.action.disabled,
                },
              }}
            >
              Buscar
            </Button>
          </Box>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default SearchFilters;
