import { BUSINESS_FIELDS } from '@constants/cnaes';
import CNAES from '@data/CNAE.json';
import { Autocomplete, Box, Chip, Divider, TextField } from '@mui/material';
import React, { useMemo } from 'react';

interface CnaeSelectorOption {
  id: string;
  label: string;
  type: 'area' | 'cnae';
  value: string;
  relatedCnaes?: string[];
}

interface CnaeSelectorProps {
  value: string[];
  onChange: (cnaes: string[]) => void;
  label?: string;
  className?: string;
  showExternalTags?: boolean;
}

const CnaeSelector: React.FC<CnaeSelectorProps> = ({
  value,
  onChange,
  label = 'CNAE',
  className = '',
  showExternalTags = false,
}) => {
  // Memoize options to prevent unnecessary recalculations
  const options: CnaeSelectorOption[] = useMemo(
    () => [
      // Add business areas
      ...BUSINESS_FIELDS.map((field) => ({
        id: field.id,
        label: field.name,
        type: 'area' as const,
        value: `area:${field.id}`,
        relatedCnaes: field.relatedCnaes,
      })),

      // Add divider option
      {
        id: 'divider',
        label: '──────────────',
        type: 'cnae',
        value: 'divider',
      },

      // Add individual CNAEs from JSON file
      ...CNAES.map((cnae) => ({
        id: cnae.cod,
        label: `${cnae.cod} - ${cnae.desc}`,
        type: 'cnae' as const,
        value: cnae.cod,
      })),
    ],
    [],
  ); // Empty dependency array since BUSINESS_FIELDS and CNAES are static

  // Memoize selected options
  const selectedOptions = useMemo(
    () =>
      value
        .map(
          (cnae) =>
            options.find((opt) => opt.value === cnae) ||
            options.find((opt) => opt.type === 'area' && opt.relatedCnaes?.includes(cnae)),
        )
        .filter((opt): opt is CnaeSelectorOption => opt !== undefined),
    [value, options],
  );

  const handleChange = (_: any, newValue: CnaeSelectorOption[]) => {
    const newCnaes = new Set<string>(value); // Initialize with existing values

    // Handle removals
    const removedOptions = selectedOptions.filter(
      (opt) => !newValue.some((newOpt) => newOpt.value === opt.value),
    );
    removedOptions.forEach((opt) => {
      if (opt.type === 'area' && opt.relatedCnaes) {
        opt.relatedCnaes.forEach((cnae) => newCnaes.delete(cnae));
      } else {
        newCnaes.delete(opt.value);
      }
    });

    // Handle additions
    const addedOptions = newValue.filter(
      (opt) => !selectedOptions.some((selOpt) => selOpt.value === opt.value),
    );
    addedOptions.forEach((option) => {
      if (option.type === 'area' && option.relatedCnaes) {
        option.relatedCnaes.forEach((cnae) => newCnaes.add(cnae));
      } else if (option.type === 'cnae' && option.value !== 'divider') {
        newCnaes.add(option.value);
      }
    });

    onChange(Array.from(newCnaes));
  };

  return (
    <div className={className}>
      <Autocomplete
        multiple
        options={options}
        value={selectedOptions}
        onChange={handleChange}
        getOptionLabel={(option) => option.label}
        handleHomeEndKeys
        selectOnFocus
        clearOnBlur={false}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        groupBy={(option) => (option.type === 'area' ? 'Áreas de Negócio' : 'CNAEs')}
        renderOption={(props, option) => {
          if (option.value === 'divider') {
            return <Divider />;
          }
          return <li {...props}>{option.label}</li>;
        }}
        renderInput={(params) => (
          <TextField {...params} label={label} placeholder="Selecione áreas ou CNAEs específicos" />
        )}
        renderTags={() => null}
      />
      {!showExternalTags && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
          {selectedOptions.map((option) => (
            <Chip
              key={option.value}
              label={option.label}
              color={option.type === 'area' ? 'primary' : 'default'}
              onDelete={() => {
                const newValue = selectedOptions.filter((opt) => opt.value !== option.value);
                handleChange(null, newValue);
              }}
            />
          ))}
        </Box>
      )}
    </div>
  );
};

export default CnaeSelector;
