interface RetryOptions<T> {
  operation: () => Promise<T>;
  maxRetries?: number;
  delay?: number | ((attempt: number) => number);
  shouldRetry?: (error: any) => boolean;
  onRetry?: (attempt: number, error: any) => void;
  onSuccess?: (result: T) => void;

  // Return false to rethrow the error
  onFailure?: (error: any) => boolean;
}

export async function retry<T>({
  operation,
  maxRetries = 3,
  delay = 1000,
  shouldRetry = () => true,
  onRetry = () => {},
  onSuccess = () => {},
  onFailure = () => true,
}: RetryOptions<T>): Promise<T> {
  let lastError: any;
  let result: T | undefined;

  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      result = await operation();
      onSuccess(result);
      return result;
    } catch (error) {
      lastError = error;

      if (!shouldRetry(error)) {
        break;
      }

      if (attempt === maxRetries) {
        console.error(error, { attempt });
        break;
      }

      onRetry(attempt, error);

      const delayTime = typeof delay === 'function' ? delay(attempt) : delay;
      await new Promise((resolve) => setTimeout(resolve, delayTime));
    }
  }

  if (onFailure(lastError)) {
    throw lastError;
  }
  return result as T;
}
