import {
  Box,
  CircularProgress,
  Fade,
  Pagination as MuiPagination,
  Typography,
} from '@mui/material';
import React from 'react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  isLoading?: boolean;
}

interface PaginationInfoProps {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
  isLoading?: boolean;
  id?: string;
}

export const PaginationInfo: React.FC<PaginationInfoProps> = ({
  currentPage,
  totalPages,
  totalItems,
  itemsPerPage,
  isLoading = false,
  id,
}) => {
  const validCurrentPage = Math.max(1, Math.min(currentPage, totalPages));
  const startItem = (validCurrentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(validCurrentPage * itemsPerPage, totalItems);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} id={id}>
      <Typography variant="body2" color="text.secondary">
        {startItem}-{endItem} de {totalItems}
      </Typography>
      {isLoading && (
        <Fade in={isLoading}>
          <CircularProgress size={20} />
        </Fade>
      )}
    </Box>
  );
};

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  totalItems,
  itemsPerPage,
  onPageChange,
  isLoading = false,
}) => {
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(value);
  };

  const validCurrentPage = Math.max(1, Math.min(currentPage, totalPages));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2,
        mt: 3,
        mb: 2,
      }}
    >
      <PaginationInfo
        currentPage={validCurrentPage}
        totalPages={totalPages}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        isLoading={isLoading}
      />
      <MuiPagination
        count={totalPages}
        page={validCurrentPage}
        onChange={handleChange}
        color="primary"
        showFirstButton
        showLastButton
        siblingCount={1}
        disabled={isLoading}
      />
    </Box>
  );
};

export default Pagination;
