import logo from '@assets/logo.png';

// import logoLight from '@assets/logo-light.png';
import { Box, CircularProgress, keyframes } from '@mui/material';
import { useColorScheme } from '@mui/material/styles';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const pulse = keyframes`
  0% {
    transform: rotate(0deg) scale(0.95);
    opacity: 0.5;
  }
  50% {
    transform: rotate(180deg) scale(1);
    opacity: 0.8;
  }
  100% {
    transform: rotate(360deg) scale(0.95);
    opacity: 0.5;
  }
`;

interface RotatingLogoProps {
  color?: string;
  size?: number;
}

const RotatingLogo = ({ color = '#1976d2', size = 60 }: RotatingLogoProps) => {
  const { mode } = useColorScheme();

  // const theme = useTheme();

  const logoSrc = mode === 'dark' ? logo : logo;

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress
        size={size}
        thickness={4}
        sx={{
          color,
          animation: `${rotate} 2s linear infinite`,
        }}
      />
      <Box
        component="img"
        src={logoSrc}
        alt="Licitou"
        sx={{
          position: 'absolute',
          width: `${size / 2}px`,
          height: `${size / 2}px`,
          animation: `${pulse} 2s ease-in-out infinite`,
          transformOrigin: 'center center',
          filter: mode === 'dark' ? 'brightness(1)' : 'brightness(0.8)',
        }}
      />
    </Box>
  );
};

export default RotatingLogo;
