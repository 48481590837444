import { useAnalytics } from '@hooks/useAnalytics';
import { useAuth } from '@hooks/useAuth';
import Intercom from '@intercom/messenger-js-sdk';
import { useProfileStore } from '@store/profileStore';
import React, { useEffect, useRef } from 'react';

const IntercomChat: React.FC = () => {
  const { userData, company, currentProfileId } = useProfileStore();
  const { user } = useAuth();
  const { trackEvent } = useAnalytics();
  const isInitialized = useRef(false);

  useEffect(() => {
    if (user && userData && userData.uid && !isInitialized.current) {
      isInitialized.current = true;
      const createdAt = userData.createdAt
        ? new Date(userData.createdAt).getTime() / 1000
        : Date.now() / 1000;

      Intercom({
        app_id: 'h0zkn1zq',
        user_id: user.uid || userData.uid,
        name: userData.name || user.displayName || '',
        email: userData.email || user.email || '',
        created_at: Math.floor(createdAt),
        company: company?.razaoSocial || '',
        custom_data: {
          cnpj: company?.cnpj || '',
          profileId: currentProfileId || '',
        },
      });

      trackEvent('intercom_initialized', {
        user_name: userData.name || user.displayName,
        company_name: company?.razaoSocial,
        profile_id: currentProfileId,
      });
    }
  }, [userData, user, company, currentProfileId, trackEvent]);

  return null;
};

export default IntercomChat;
