import { BRAZILIAN_STOP_WORDS } from '@constants';
import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const getHighlightedText = (text: string, highlights: string[], theme: Theme) => {
  if (!highlights) {
    return text;
  }

  const wordsToExclude = [...BRAZILIAN_STOP_WORDS, 'pregão', 'pregao'];
  let result = text;

  highlights.forEach((highlight) => {
    const parts = highlight.split(/<em>|<\/em>/);
    parts.forEach((part, index) => {
      if (index % 2 === 1) {
        const words = part.split(/\s+/);
        const filteredWords = words.filter((word) => !wordsToExclude.includes(word.toLowerCase()));

        if (filteredWords.length > 0) {
          const highlightedPart = filteredWords.join(' ');
          result = result.replace(
            part,
            `<mark style="background-image: linear-gradient(to right, ${alpha(
              theme.palette.primary.main,
              0.1,
            )}, ${alpha(theme.palette.primary.main, 0.2)}); padding: 2px 0;">${highlightedPart}</mark>`,
          );
        }
      }
    });
  });

  return result;
};
