import { Badge, Close, Content, Navigation } from '@components/Tour/TourComponents';
import { TOUR_IDS, TourSteps } from '@constants/steps';
import { useTheme } from '@mui/material/styles';
import { TourProvider as ReactourProvider, StepType, useTour as useReactour } from '@reactour/tour';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface TourContextData {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  currentTourId: string | null;
  setCurrentTourId: (tourId: string | null) => void;
  completedTours: Record<string, boolean>;
  completeTour: (tourId: string) => void;
  resetTour: (tourId: string) => void;
  setCloseCallback: (callback: () => void) => void;
}

const TourContext = createContext<TourContextData>({} as TourContextData);

interface TourContentProviderProps {
  children: React.ReactNode;
  setCloseCallback: (callback: () => void) => void;
  setTourControls: (controls: {
    setSteps?: (steps: StepType[]) => void;
    setCurrentStep?: (step: number) => void;
  }) => void;
}

const TourContentProvider: React.FC<TourContentProviderProps> = ({
  children,
  setCloseCallback,
  setTourControls,
}) => {
  const { setIsOpen: setReactourIsOpen, setSteps, setCurrentStep, isOpen } = useReactour();
  const [currentTourId, setCurrentTourId] = useState<string | null>(null);
  const [completedTours, setCompletedTours] = useState<Record<string, boolean>>(() => {
    const stored = localStorage.getItem('completed-tours');
    return stored ? JSON.parse(stored) : {};
  });

  const setIsOpen = (open: boolean) => {
    if (!open) {
      if (setSteps) {
        setSteps([]);
      }

      setCurrentStep(0);
    }
    setReactourIsOpen(open);
  };

  const completeTour = (tourId: string) => {
    setCompletedTours((prev) => ({ ...prev, [tourId]: true }));
    setIsOpen(false);
  };

  const resetTour = (tourId: string) => {
    setCompletedTours((prev) => ({ ...prev, [tourId]: false }));
  };

  useEffect(() => {
    if (currentTourId) {
      if (setSteps) {
        setSteps([]);
      }
      setCurrentStep(0);

      const steps = TourSteps[currentTourId as keyof typeof TourSteps] || [];

      // Filter out steps where the target element doesn't exist
      const availableSteps = steps.filter((step) => {
        if (!step.selector) return true; // Keep steps without selectors

        // Check if the element exists in the DOM
        const element = document.querySelector(step.selector);
        if (!element) return false;

        // If there are highlighted selectors, check if at least one exists
        if (step.highlightedSelectors?.length) {
          return step.highlightedSelectors.some((selector) => document.querySelector(selector));
        }

        return true;
      });

      // Only set steps if there are available steps
      if (availableSteps.length > 0 && setSteps) {
        setSteps(availableSteps as unknown as StepType[]);
      } else {
        console.log('No steps available for tour', currentTourId);

        // If no steps are available, complete the tour
        completeTour(currentTourId);
      }
    }
  }, [currentTourId, setSteps]);

  useEffect(() => {
    localStorage.setItem('completed-tours', JSON.stringify(completedTours));
  }, [completedTours]);

  useEffect(() => {
    setTourControls({ setSteps, setCurrentStep });
  }, [setSteps, setCurrentStep, setTourControls]);

  return (
    <TourContext.Provider
      value={{
        isOpen,
        setIsOpen,
        currentTourId,
        setCurrentTourId,
        completedTours,
        completeTour,
        resetTour,
        setCloseCallback,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};

export const TourProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [closeCallback, setCloseCallback] = useState<(() => void) | null>(null);
  const theme = useTheme();
  const [tourControls, setTourControls] = useState<{
    setSteps?: (steps: StepType[]) => void;
    setCurrentStep?: (step: number) => void;
  }>({});

  return (
    <ReactourProvider
      steps={[]}
      disableInteraction
      disableKeyboardNavigation={false}
      beforeClose={() => {
        if (closeCallback) {
          closeCallback();
          setCloseCallback(null);
        }
        if (tourControls.setSteps) {
          tourControls.setSteps([]);
        }
        if (tourControls.setCurrentStep) {
          tourControls.setCurrentStep(0);
        }
      }}
      components={{
        Close,
        Navigation,
        Content,
        Badge,
      }}
      styles={{
        popover: (base) => ({
          ...base,
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          boxShadow: theme.shadows[10],
          borderRadius: theme.shape.borderRadius,
          padding: 0,
        }),
        badge: (base) => ({
          ...base,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        }),
        arrow: (base) => ({
          ...base,
          color: theme.palette.background.paper,
        }),
        controls: (base) => ({
          ...base,
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          padding: theme.spacing(2),
          borderTop: `1px solid ${theme.palette.divider}`,
        }),
        dot: (base) => ({
          ...base,
          backgroundColor: theme.palette.action.disabled,
          '&[data-tour-elem="is-active"]': {
            backgroundColor: theme.palette.primary.main,
          },
        }),
        close: (base) => ({
          ...base,
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.text.primary,
          },
        }),
        button: (base) => ({
          ...base,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
        }),
      }}
    >
      <TourContentProvider setCloseCallback={setCloseCallback} setTourControls={setTourControls}>
        {children}
      </TourContentProvider>
    </ReactourProvider>
  );
};

export const useTour = () => useContext(TourContext);

export const getTourIdFromPath = (path: string) => {
  if (path.startsWith('/licitacao/')) {
    return 'licitacao.detalhes';
  }
  if (path.startsWith('/boletins/')) {
    return 'boletins';
  }
  return path.substring(1).replace(/\//g, '.');
};

export const isTourAvailable = (path: string) => {
  const tourId = getTourIdFromPath(path);
  return Object.values(TOUR_IDS).includes(tourId as (typeof TOUR_IDS)[keyof typeof TOUR_IDS]);
};
