import FavoriteButton from '@components/Favorite/FavoriteButton';
import FollowingButton from '@components/Following/FollowingButton';
import TagButton from '@components/Tag/TagButton';
import {
  MoreVert,
  Star as StarIcon,
  Visibility as VisibilityIcon,
  VisibilityOff,
} from '@mui/icons-material';
import { Box, Chip, IconButton, MenuItem } from '@mui/material';
import { useFavoritesStore } from '@store/favoritesStore';
import { useFollowingStore } from '@store/followingStore';
import React, { useCallback } from 'react';

interface LicitacaoActionsProps {
  licitacaoId: string;
  isFirst?: boolean;
  isColumnView?: boolean;
}

const LicitacaoActions: React.FC<LicitacaoActionsProps> = ({
  licitacaoId,
  isFirst = false,
  isColumnView = false,
}) => {
  const { isFavorite, addFavorite } = useFavoritesStore();
  const { isFollowing, addFollowing, removeFollowing } = useFollowingStore();

  const handlePromoteToFollowing = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      addFollowing(licitacaoId);
    },
    [licitacaoId, addFollowing],
  );

  const ActionsMenu = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const menuRef = React.useRef<HTMLDivElement>(null);

    // Handle clicks outside
    React.useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
          setIsOpen(false);
        }
      };

      if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isOpen]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setIsOpen(!isOpen);
    };

    const handleFavoriteAndFollow = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsOpen(false);
      addFavorite(licitacaoId).then(() => addFollowing(licitacaoId));
    };

    const handleUnfollowClick = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsOpen(false);
      removeFollowing(licitacaoId);
    };

    return (
      <Box sx={{ position: 'relative' }} ref={menuRef}>
        <IconButton size="small" onClick={handleClick} sx={{ opacity: 0.7 }}>
          <MoreVert fontSize="small" />
        </IconButton>

        {isOpen && (
          <Box
            sx={{
              position: 'absolute',
              top: 'calc(100% - 10px)', // Moved up 4px
              right: 0,
              mt: 1,
              bgcolor: 'background.paper',
              borderRadius: 1,
              boxShadow: 2,
              minWidth: 200,
              zIndex: 1500,
              py: 1,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {!isFavorite(licitacaoId) && (
              <MenuItem onClick={handleFavoriteAndFollow}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <StarIcon fontSize="small" />
                  <VisibilityIcon fontSize="small" />
                  Favoritar e Acompanhar
                </Box>
              </MenuItem>
            )}
            {isFollowing(licitacaoId) && (
              <MenuItem onClick={handleUnfollowClick}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <VisibilityOff fontSize="small" />
                  Deixar de Acompanhar
                </Box>
              </MenuItem>
            )}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        position: 'relative',
        zIndex: 1,
        flexDirection: isColumnView ? 'column' : 'row',
      }}
    >
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        {!isColumnView && (
          <TagButton
            licitacaoId={licitacaoId}
            dataTour={isFirst ? 'tag-button-first' : undefined}
          />
        )}
        {isFollowing(licitacaoId) && (
          <Chip
            icon={<VisibilityIcon fontSize="small" />}
            label={isColumnView ? 'Acomp.' : 'Acompanhando'}
            size="small"
            color="primary"
            variant="outlined"
            sx={{ height: 24 }}
          />
        )}
        <FavoriteButton
          dataTour={isFirst ? 'favorite-button-first' : undefined}
          licitacaoId={licitacaoId}
        />

        {isFavorite(licitacaoId) && !isFollowing(licitacaoId) && (
          <FollowingButton
            onClick={handlePromoteToFollowing}
            dataTour={isFirst ? 'following-button-first' : undefined}
          />
        )}

        {(isFollowing(licitacaoId) || !isFavorite(licitacaoId)) && <ActionsMenu />}
      </Box>
      {isColumnView && (
        <TagButton licitacaoId={licitacaoId} dataTour={isFirst ? 'tag-button-first' : undefined} />
      )}
    </Box>
  );
};

export default LicitacaoActions;
