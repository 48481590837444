import Api from '@services/api';
import { useProfileStore } from '@store/profileStore';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface ProfileContextType {
  currentProfileId: string | null;
  refreshProfile: () => void;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const useProfile = (): ProfileContextType => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};

export const ProfileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { currentProfileId, setCurrentProfileId, setCurrentProfile } = useProfileStore();
  const [profileVersion, setProfileVersion] = useState(0);

  const refreshProfile = useCallback(() => {
    setProfileVersion((prev) => prev + 1);
  }, []);

  useEffect(() => {
    const storedProfileId = localStorage.getItem('currentProfileId');
    if (storedProfileId && storedProfileId !== currentProfileId) {
      setCurrentProfileId(storedProfileId);
      Api.setCurrentProfileId(storedProfileId);
    }
  }, [setCurrentProfileId, currentProfileId]);

  useEffect(() => {
    if (currentProfileId) {
      localStorage.setItem('currentProfileId', currentProfileId);
      Api.setCurrentProfileId(currentProfileId);
      setCurrentProfile(currentProfileId);
    }
  }, [currentProfileId, profileVersion, setCurrentProfile]);

  return (
    <ProfileContext.Provider value={{ currentProfileId, refreshProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};
