import { Box, Button, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { retry } from '@utils/retry';
import { lazy, Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';

import { useColorScheme } from '@mui/material/styles';
import Login from '@pages/Login';
import Search from '@pages/Search';

import Loading from '@components/Layout/Loading';
import SuspenseLoading from '@components/Layout/SuspenseLoading';
import PrivateRoute from '@components/PrivateRoute';
import { AuthProvider, useAuth } from '@contexts/AuthContext';
import { NotificationProvider } from '@contexts/NotificationContext';
import { ProfileProvider } from '@contexts/ProfileContext';
import { TourProvider } from '@contexts/TourContext';
import { useAnalytics } from '@hooks/useAnalytics';
import AppTheme from '@theme/AppTheme';
import { ptBR } from 'date-fns/locale/pt-BR';

// Add error fallback component
const ChunkErrorFallback = () => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="100vh"
  >
    <Typography variant="h6" gutterBottom>
      Erro ao carregar o conteúdo
    </Typography>
    <Button variant="contained" onClick={() => window.location.reload()}>
      Recarregar página
    </Button>
  </Box>
);

// Modify lazy imports to include retry logic
const retryLoadComponent = (componentImport: () => Promise<any>) =>
  retry({
    operation: () => componentImport(),
    maxRetries: 3,
    delay: (attempt: number) => Math.pow(1000, attempt) * 1000,
    onRetry: (attempt, error) => {
      console.error(`Erro ao carregar o componente: ${error.message}. Tentativa ${attempt}`);
    },
  });

// Update lazy imports with retry
const Bulletin = lazy(() =>
  retryLoadComponent(() => import(/* webpackChunkName: "bulletin" */ '@pages/Bulletin')),
);
const LicitacaoDetails = lazy(() =>
  retryLoadComponent(() => import(/* webpackChunkName: "details" */ '@pages/Details')),
);
const Declarations = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "declarations" */ '@pages/Documents/Declarations'),
  ),
);
const Proposal = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "declarations" */ '@pages/Documents/Proposal'),
  ),
);
const Documents = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "documents" */ '@pages/Documents/Documents'),
  ),
);
const DocumentsTab = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "documents-tab" */ '@pages/Documents/index'),
  ),
);
const Following = lazy(() =>
  retryLoadComponent(() => import(/* webpackChunkName: "following" */ '@pages/Following')),
);
const FollowingAgenda = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "following-agenda" */ '@pages/Following/Agenda'),
  ),
);
const FollowingList = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "following-list" */ '@pages/Following/Following'),
  ),
);
const FollowingKanban = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "following-kanban" */ '@pages/Following/Kanban'),
  ),
);
const MinhasLicitacoes = lazy(() =>
  retryLoadComponent(() => import(/* webpackChunkName: "my-bids" */ '@pages/MyLicitacoes')),
);
const FavoriteLicitacoes = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "favorite-bids" */ '@pages/MyLicitacoes/Favorite'),
  ),
);
const RecommendedLicitacoes = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "recommended-bids" */ '@pages/MyLicitacoes/Recommended'),
  ),
);
const Company = lazy(() =>
  retryLoadComponent(() => import(/* webpackChunkName: "company" */ '@pages/Company')),
);
const CompanyProfile = lazy(() =>
  retryLoadComponent(() => import(/* webpackChunkName: "company-info" */ '@pages/Company/Profile')),
);
const Preferences = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "preferences" */ '@pages/Company/Preferences'),
  ),
);
const Companies = lazy(() =>
  retryLoadComponent(() => import(/* webpackChunkName: "companies" */ '@pages/Company/Companies')),
);
const Settings = lazy(() =>
  retryLoadComponent(() => import(/* webpackChunkName: "settings" */ '@pages/Settings/index')),
);
const Payments = lazy(() =>
  retryLoadComponent(() => import(/* webpackChunkName: "payments" */ '@pages/Settings/Payments')),
);
const UserProfile = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "user-profile" */ '@pages/Settings/UserProfile'),
  ),
);
const NotFound = lazy(() =>
  retryLoadComponent(() => import(/* webpackChunkName: "not-found" */ '@pages/NotFound')),
);

function AppContent() {
  const { user, isLoading, signInWithToken, getUser } = useAuth();
  const [isProcessingToken, setIsProcessingToken] = useState(false);
  const location = useLocation();
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    const handleSignInWithToken = async () => {
      const urlParams = new URLSearchParams(location.search);
      const token = urlParams.get('token');
      const shouldStartTour = urlParams.get('onboarding') === 'true';

      if (token && !isProcessingToken) {
        setIsProcessingToken(true);
        try {
          await signInWithToken(token);
          trackEvent('user_sign_in', { method: 'token' });
          if (shouldStartTour) {
            trackEvent('start_tour');
            const searchParams = new URLSearchParams();
            searchParams.set('tour', '1');
            window.location.href = `/busca?${searchParams.toString()}`;
          }
        } catch (error) {
          trackEvent('sign_in_error', { error: (error as Error).message });
          console.error('Error signing in with token:', error);
        } finally {
          setIsProcessingToken(false);
        }
      }
    };

    handleSignInWithToken();
  }, [location.search, signInWithToken, isProcessingToken, trackEvent]);

  if (isLoading || isProcessingToken) {
    return <Loading withHeader={true} />;
  }

  return (
    <ErrorBoundary FallbackComponent={ChunkErrorFallback}>
      <Routes>
        <Route path="/login" element={user ? <Navigate replace to="/busca" /> : <Login />} />
        <Route
          path="/empresa"
          element={
            <PrivateRoute>
              <Suspense fallback={<SuspenseLoading />}>
                <Company />
              </Suspense>
            </PrivateRoute>
          }
        >
          <Route path="perfil" element={<CompanyProfile />} />
          <Route path="preferencias" element={<Preferences />} />
          <Route path="multi" element={<Companies />} />
          <Route index element={<Navigate replace to="perfil" />} />
        </Route>
        <Route
          path="/configuracoes"
          element={
            <PrivateRoute>
              <Suspense fallback={<SuspenseLoading />}>
                <Settings />
              </Suspense>
            </PrivateRoute>
          }
        >
          <Route path="pagamento" element={<Payments />} />
          <Route path="perfil" element={<UserProfile />} />
          <Route index element={<Navigate replace to="pagamento" />} />
        </Route>
        <Route
          path="/documentos"
          element={
            <PrivateRoute>
              <Suspense fallback={<SuspenseLoading />}>
                <DocumentsTab />
              </Suspense>
            </PrivateRoute>
          }
        >
          <Route path="documentos" element={<Documents />} />
          <Route path="declaracoes" element={<Declarations />} />
          <Route path="proposta" element={<Proposal />} />
          <Route index element={<Navigate replace to="documentos" />} />
        </Route>
        <Route
          path="/busca"
          element={
            <PrivateRoute>
              <Suspense fallback={<SuspenseLoading />}>
                <Search />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/recomendadas"
          element={
            <PrivateRoute>
              <Suspense fallback={<SuspenseLoading />}>
                <RecommendedLicitacoes />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/minhas-licitacoes"
          element={
            <PrivateRoute>
              <Suspense fallback={<SuspenseLoading />}>
                <MinhasLicitacoes />
              </Suspense>
            </PrivateRoute>
          }
        >
          <Route path="recomendadas" element={<RecommendedLicitacoes />} />
          <Route path="favoritas" element={<FavoriteLicitacoes />} />
          <Route index element={<Navigate replace to="recomendadas" />} />
        </Route>
        <Route
          path="/licitacao/:id_licitacao"
          element={
            <PrivateRoute>
              <Suspense fallback={<SuspenseLoading />}>
                <LicitacaoDetails />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/boletins"
          element={
            <PrivateRoute>
              <Suspense fallback={<SuspenseLoading />}>
                <Bulletin />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/boletins/:bulletinId"
          element={
            <PrivateRoute>
              <Suspense fallback={<SuspenseLoading />}>
                <Bulletin />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/acompanhando"
          element={
            <PrivateRoute>
              <Suspense fallback={<SuspenseLoading />}>
                <Following />
              </Suspense>
            </PrivateRoute>
          }
        >
          <Route path="lista" element={<FollowingList />} />
          <Route path="kanban" element={<FollowingKanban />} />
          <Route path="agenda" element={<FollowingAgenda />} />
          <Route index element={<Navigate replace to="lista" />} />
        </Route>
        <Route
          path="/"
          element={
            isProcessingToken ? (
              <SuspenseLoading />
            ) : (
              <Navigate replace to={getUser() ? '/busca' : '/login'} />
            )
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<SuspenseLoading />}>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
    </ErrorBoundary>
  );
}

function AppWithRouter() {
  const { mode } = useColorScheme();
  const location = useLocation();

  const isLoginPage = location.pathname === '/login';
  const themeMode = isLoginPage ? 'dark' : mode;

  return (
    <AppTheme mode={themeMode}>
      <AppContent />
    </AppTheme>
  );
}

function App() {
  return (
    <HelmetProvider>
      <Helmet defaultTitle="Licitou" titleTemplate="%s - Licitou">
        <meta name="description" content="Plataforma de licitações" />
      </Helmet>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <Router>
          <AuthProvider>
            <ProfileProvider>
              <TourProvider>
                <NotificationProvider>
                  <AppWithRouter />
                </NotificationProvider>
              </TourProvider>
            </ProfileProvider>
          </AuthProvider>
        </Router>
      </LocalizationProvider>
    </HelmetProvider>
  );
}

export default App;
