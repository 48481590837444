import { useBulletinStore } from '@store/bulletinStore';
import { useFavoritesStore } from '@store/favoritesStore';
import { useFollowingStore } from '@store/followingStore';
import { useKanbanStore } from '@store/kanbanStore';
import { useLicitacoesStore } from '@store/licitacoesStore';
import { usePaymentStore } from '@store/paymentStore';
import { useProfileStore } from '@store/profileStore';

export const resetAllStores = () => {
  useProfileStore.getState().resetStore();
  usePaymentStore.getState().resetStore();
  useLicitacoesStore.getState().resetStore();
  useFollowingStore.getState().resetStore();
  useFavoritesStore.getState().resetStore();
  useBulletinStore.getState().resetStore();
  useKanbanStore.getState().resetStore();
};
