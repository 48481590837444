export const TOUR_IDS = {
  SEARCH: 'busca',
  FOLLOWING_LIST: 'acompanhando.lista',
  FOLLOWING_KANBAN: 'acompanhando.kanban',
  FOLLOWING_AGENDA: 'acompanhando.agenda',
  RECOMMENDED: 'minhas-licitacoes.recomendadas',
  FAVORITES: 'minhas-licitacoes.favoritas',
  DOCUMENTS: 'documentos.documentos',
  DECLARATIONS: 'documentos.declaracoes',
  SETTINGS_COMPANY_INFO: 'empresa.perfil',
  SETTINGS_PREFERENCES: 'empresa.preferencias',
  BULLETIN: 'boletins',
  LICITACAO_DETAILS: 'licitacao.detalhes',
} as const;

export const TourSteps = {
  [TOUR_IDS.SEARCH]: [
    {
      selector: '.search-box',
      content:
        'Digite palavras-chave para buscar licitações. Pressione ENTER para adicionar mais de uma palavra.',
      highlightedSelectors: ['.search-box'],
      position: 'right',
    },
    {
      selector: '.search-expand-button',
      content: 'Clique aqui para mostrar mais filtros avançados de busca.',
      highlightedSelectors: ['.search-expand-button'],
      position: 'right',
    },
    {
      selector: '.saved-searches',
      content: 'Aqui você encontra suas buscas salvas e pode acessá-las rapidamente.',
      highlightedSelectors: ['.saved-searches'],
    },
    {
      selector: '.save-search-button',
      content: 'Salve sua busca atual para acessá-la facilmente depois.',
      highlightedSelectors: ['.save-search-button'],
    },

    // Step for when there are filters
    {
      selector: '.search-filters-expanded',
      content:
        'Use os filtros para refinar sua busca por estado, modalidade, data de abertura e muito mais.',
      highlightedSelectors: ['.search-filters-expanded'],
    },

    // Step for when there are no results
    {
      selector: '.search-welcome-message',
      content: 'Os resultados da sua busca aparecerão aqui.',
      position: 'top',
      highlightedSelectors: ['.search-welcome-message'],
    },

    // Steps for when there are results
    {
      selector: '.licitacao-card',
      content:
        'Cada licitação mostra informações importantes como valor, data de abertura e órgão.',
      highlightedSelectors: ['.licitacao-card'],
    },
    {
      selector: '.favorite-button',
      content: 'Marque as licitações como favoritas para acompanhá-las depois.',
      highlightedSelectors: ['.favorite-button'],
    },
    {
      selector: '.following-button',
      content: 'Acompanhe as licitações para receber atualizações sobre elas.',
      highlightedSelectors: ['.following-button'],
    },
  ],
  [TOUR_IDS.FOLLOWING_LIST]: [
    {
      selector: '.following-list',
      content: 'Aqui você pode ver todas as licitações que está acompanhando.',
      highlightedSelectors: ['.following-list'],
    },

    // For users with following items
    {
      selector: '.following-grid',
      content: 'Veja todas as licitações que você está acompanhando.',
      highlightedSelectors: ['.following-grid'],
    },
    {
      selector: '.following-card',
      content: 'Cada card mostra os detalhes da licitação que você está acompanhando.',
      highlightedSelectors: ['.following-card'],
    },

    // For new users
    {
      selector: '.following-empty',
      content: 'Comece a acompanhar licitações para vê-las aqui.',
      highlightedSelectors: ['.following-empty'],
    },
  ],
  [TOUR_IDS.FOLLOWING_KANBAN]: [
    {
      selector: '.kanban-container',
      content: 'Organize suas licitações em um quadro Kanban personalizado.',
      highlightedSelectors: ['.kanban-container'],
    },
    {
      selector: '.kanban-add-button',
      content: 'Adicione licitações que você está acompanhando ao quadro.',
      highlightedSelectors: ['.kanban-add-button'],
    },

    // For empty state
    {
      selector: '.kanban-empty',
      content: 'Comece adicionando licitações ao seu quadro para organizá-las.',
      highlightedSelectors: ['.kanban-empty'],
    },

    // For populated state
    {
      selector: '.kanban-board',
      content: 'Visualize e organize suas licitações em diferentes estágios.',
      highlightedSelectors: ['.kanban-board'],
    },
    {
      selector: '.kanban-column',
      content: 'Arraste e solte as licitações entre as colunas para atualizar seu status.',
      highlightedSelectors: ['.kanban-column'],
    },
    {
      selector: '.kanban-card',
      content: 'Cada card representa uma licitação. Arraste para mudar seu status.',
      highlightedSelectors: ['.kanban-card'],
    },
  ],
  [TOUR_IDS.FOLLOWING_AGENDA]: [
    {
      selector: '.agenda-container',
      content: 'Visualize todas as suas licitações em um calendário organizado.',
      highlightedSelectors: ['.agenda-container'],
    },
    {
      selector: '.agenda-toolbar',
      content: 'Use estes controles para navegar entre os meses e voltar para hoje.',
      highlightedSelectors: ['.agenda-toolbar'],
    },
    {
      selector: '.agenda-navigation',
      content: 'Navegue entre os meses do calendário.',
      highlightedSelectors: ['.agenda-navigation'],
    },
    {
      selector: '.agenda-current-month',
      content: 'Veja o mês atual que está sendo exibido.',
      highlightedSelectors: ['.agenda-current-month'],
    },
    {
      selector: '.agenda-calendar',
      content:
        'O calendário mostra todas as datas de abertura das licitações que você está acompanhando.',
      highlightedSelectors: ['.agenda-calendar'],
    },

    // For populated state
    {
      selector: '.agenda-event',
      content: 'Clique em uma licitação para ver mais detalhes.',
      highlightedSelectors: ['.agenda-event'],
    },

    // For empty state
    {
      selector: '.agenda-empty',
      content: 'Comece a acompanhar licitações para vê-las no calendário.',
      highlightedSelectors: ['.agenda-empty'],
    },
    {
      selector: '.agenda-follow-button',
      content: 'Use este botão nas licitações para começar a acompanhá-las.',
      highlightedSelectors: ['.agenda-follow-button'],
    },
  ],
  [TOUR_IDS.RECOMMENDED]: [
    {
      selector: '.recommended-list',
      content: 'Licitações recomendadas com base nas suas preferências.',
      highlightedSelectors: ['.recommended-list'],
    },
    {
      selector: '.recommended-grid',
      content: 'Veja todas as licitações recomendadas para você.',
      highlightedSelectors: ['.recommended-grid'],
    },

    // For populated state
    {
      selector: '.recommendation-card',
      content: 'Veja por que esta licitação foi recomendada para você.',
      highlightedSelectors: ['.recommendation-card'],
    },

    // For new users
    {
      selector: '.recommended-empty',
      content: 'Complete seu perfil para começar a receber recomendações personalizadas.',
      highlightedSelectors: ['.recommended-empty'],
    },
    {
      selector: '.recommended-complete-profile',
      content: 'Clique aqui para completar seu perfil e começar a receber recomendações.',
      highlightedSelectors: ['.recommended-complete-profile'],
    },
  ],
  [TOUR_IDS.FAVORITES]: [
    {
      selector: '.favorites-list',
      content: 'Acesse rapidamente as licitações que você marcou como favoritas.',
      highlightedSelectors: ['.favorites-list'],
    },

    // For users with favorites
    {
      selector: '.favorites-grid',
      content: 'Veja todas as licitações que você marcou como favoritas.',
      highlightedSelectors: ['.favorites-grid'],
    },
    {
      selector: '.favorite-card',
      content:
        'Cada card mostra os detalhes da licitação favoritou, e clique em acompanhar nas que tiver mais interesse para receber notificações.',
      highlightedSelectors: ['.favorite-card'],
    },

    // For new users
    {
      selector: '.favorites-empty',
      content: 'Comece a marcar licitações como favoritas para vê-las aqui.',
      highlightedSelectors: ['.favorites-empty'],
    },
  ],
  [TOUR_IDS.DOCUMENTS]: [
    {
      selector: '.documents-header',
      content: 'Gerencie todos os seus documentos em um só lugar.',
      highlightedSelectors: ['.documents-header'],
    },
    {
      selector: '.documents-download-all',
      content: 'Baixe todos os seus documentos de uma vez em um arquivo ZIP.',
      highlightedSelectors: ['.documents-download-all'],
    },
    {
      selector: '.documents-section',
      content: 'Os documentos são organizados por seções para facilitar o gerenciamento.',
      highlightedSelectors: ['.documents-section'],
    },
    {
      selector: '.document-status',
      content: 'Veja o status de cada documento: Válido, Vencido, ou Ausente.',
      highlightedSelectors: ['.document-status'],
    },
    {
      selector: '.document-actions',
      content: 'Faça upload, baixe, edite ou exclua seus documentos.',
      highlightedSelectors: ['.document-actions'],
    },
    {
      selector: '.document-upload-button',
      content: 'Clique aqui para fazer upload de um novo documento.',
      highlightedSelectors: ['.document-upload-button'],
    },
    {
      selector: '.document-edit',
      content: 'Edite o nome personalizado e as datas de validade do documento.',
      highlightedSelectors: ['.document-edit'],
    },
    {
      selector: '.document-declaration',
      content: 'Para declarações, você pode criar automaticamente usando nossos modelos.',
      highlightedSelectors: ['.document-declaration'],
    },
    {
      selector: '.document-external',
      content: 'Alguns documentos possuem links externos para órgãos oficiais.',
      highlightedSelectors: ['.document-external'],
    },
  ],
  [TOUR_IDS.DECLARATIONS]: [
    {
      selector: '.declarations-header',
      content: 'Gere declarações personalizadas para suas licitações.',
      highlightedSelectors: ['.declarations-header'],
    },
    {
      selector: '.declarations-warning',
      content: 'Leia este aviso importante sobre o uso dos modelos de declaração.',
      highlightedSelectors: ['.declarations-warning'],
    },
    {
      selector: '.declarations-select',
      content: 'Escolha o tipo de declaração que deseja gerar.',
      highlightedSelectors: ['.declarations-select'],
    },
    {
      selector: '.declarations-form',
      content: 'Preencha os campos necessários para gerar sua declaração.',
      highlightedSelectors: ['.declarations-form'],
    },
    {
      selector: '.declarations-preview',
      content: 'Visualize a declaração gerada antes de imprimir.',
      highlightedSelectors: ['.declarations-preview'],
    },
    {
      selector: '.declarations-print',
      content: 'Imprima ou salve sua declaração em PDF.',
      highlightedSelectors: ['.declarations-print'],
    },
  ],
  [TOUR_IDS.SETTINGS_COMPANY_INFO]: [
    {
      selector: '.settings-company-info',
      content: 'Configure os dados da sua empresa para personalizar suas recomendações.',
      highlightedSelectors: ['.settings-company-info'],
    },
    {
      selector: '.settings-company-cnpj-card',
      content: 'Digite o CNPJ da sua empresa para buscarmos automaticamente suas informações.',
      highlightedSelectors: ['.settings-company-cnpj-card'],
    },
    {
      selector: '.settings-company-cnpj-input',
      content: 'Digite o CNPJ aqui para busca automática.',
      highlightedSelectors: ['.settings-company-cnpj-input'],
    },
    {
      selector: '.settings-company-details-form',
      content: 'Confira e complete os dados da sua empresa.',
      highlightedSelectors: ['.settings-company-details-form'],
    },
    {
      selector: '.settings-company-cnae-header',
      content: 'Configure os CNAEs da sua empresa.',
      highlightedSelectors: ['.settings-company-cnae-header'],
    },
    {
      selector: '.settings-company-cnae-search',
      content: 'Busque e selecione os CNAEs da sua empresa.',
      highlightedSelectors: ['.settings-company-cnae-search'],
    },
    {
      selector: '.settings-company-cnae-chips',
      content: 'O primeiro CNAE será o principal, os demais serão secundários.',
      highlightedSelectors: ['.settings-company-cnae-chips'],
    },
  ],
  [TOUR_IDS.BULLETIN]: [
    {
      selector: '.bulletin-header',
      content: 'Aqui você pode gerenciar seus boletins personalizados de licitações.',
      highlightedSelectors: ['.bulletin-header'],
    },
    {
      selector: '.bulletin-date-picker',
      content: 'Selecione uma data para ver as licitações dos seus boletins.',
      highlightedSelectors: ['.bulletin-date-picker'],
    },
    {
      selector: '.bulletin-create',
      content: 'Crie um novo boletim personalizado.',
      highlightedSelectors: ['.bulletin-create'],
    },

    // Steps for empty state
    {
      selector: '.bulletin-empty-info',
      content:
        'Você ainda não tem boletins. Crie um para começar a acompanhar licitações de seu interesse.',
      highlightedSelectors: ['.bulletin-empty-info'],
    },
    {
      selector: '.bulletin-create-preferences',
      content: 'Crie um boletim baseado nas suas preferências já configuradas.',
      highlightedSelectors: ['.bulletin-create-preferences'],
    },

    // Steps for when bulletins exist
    {
      selector: '.bulletin-card',
      content: 'Cada card representa um boletim personalizado. Clique para ver as licitações.',
      highlightedSelectors: ['.bulletin-card'],
    },
    {
      selector: '.bulletin-menu',
      content: 'Gerencie seu boletim: edite os critérios ou exclua-o.',
      highlightedSelectors: ['.bulletin-menu'],
    },
    {
      selector: '.bulletin-licitacoes',
      content: 'Veja todas as licitações que correspondem aos critérios do boletim selecionado.',
      highlightedSelectors: ['.bulletin-licitacoes'],
    },
  ],
  [TOUR_IDS.LICITACAO_DETAILS]: [
    {
      selector: '.licitacao-info',
      content: 'Aqui você encontra as informações principais da licitação.',
      highlightedSelectors: ['.licitacao-info'],
    },
    {
      selector: '.licitacao-actions',
      content: 'Ações rápidas para interagir com a licitação.',
      highlightedSelectors: ['.licitacao-actions'],
    },
    {
      selector: '[data-tour="whatsapp-button"]',
      content: 'Compartilhe esta licitação via WhatsApp.',
      highlightedSelectors: ['[data-tour="whatsapp-button"]'],
    },
    {
      selector: '[data-tour="calendar-button"]',
      content: 'Adicione a data de abertura desta licitação ao seu calendário.',
      highlightedSelectors: ['[data-tour="calendar-button"]'],
    },
    {
      selector: '[data-tour="documents-button"]',
      content: 'Veja a lista de documentos necessários para participar.',
      highlightedSelectors: ['[data-tour="documents-button"]'],
    },
    {
      selector: '[data-tour="ai-chat-button"]',
      content: 'Tire dúvidas sobre a licitação com nossa IA.',
      highlightedSelectors: ['[data-tour="ai-chat-button"]'],
    },
    {
      selector: '[data-tour="participate-button"]',
      content: 'Veja o passo a passo para participar desta licitação.',
      highlightedSelectors: ['[data-tour="participate-button"]'],
    },
    {
      selector: '.items-tab',
      content: 'Veja todos os itens desta licitação e seus detalhes.',
      highlightedSelectors: ['.items-tab'],
    },
    {
      selector: '.documents-tab',
      content: 'Acesse a lista de documentos necessários para participar desta licitação.',
      highlightedSelectors: ['.documents-tab'],
    },
    {
      selector: '.portal-details',
      content: 'Informações adicionais do portal de origem.',
      highlightedSelectors: ['.portal-details'],
    },
  ],
  [TOUR_IDS.SETTINGS_PREFERENCES]: [
    {
      selector: '.settings-preferences',
      content: 'Configure suas preferências para receber recomendações personalizadas.',
      highlightedSelectors: ['.settings-preferences'],
    },
    {
      selector: '.settings-preferences-welcome',
      content: 'Quanto mais detalhadas suas preferências, melhores serão as recomendações.',
      highlightedSelectors: ['.settings-preferences-welcome'],
    },
    {
      selector: '.settings-preferences-location',
      content: 'Selecione os estados e cidades de seu interesse.',
      highlightedSelectors: ['.settings-preferences-location'],
    },
    {
      selector: '.settings-preferences-modalities',
      content: 'Escolha as modalidades de licitação que deseja acompanhar.',
      highlightedSelectors: ['.settings-preferences-modalities'],
    },
    {
      selector: '.settings-preferences-portals',
      content: 'Selecione os portais de licitação que deseja monitorar.',
      highlightedSelectors: ['.settings-preferences-portals'],
    },
    {
      selector: '.settings-preferences-keywords',
      content: 'Adicione palavras-chave relacionadas ao seu negócio.',
      highlightedSelectors: ['.settings-preferences-keywords'],
    },
    {
      selector: '.settings-preferences-summary',
      content: 'Descreva sua empresa para ajudar nossa IA a encontrar as melhores licitações.',
      highlightedSelectors: ['.settings-preferences-summary'],
    },
    {
      selector: '.settings-preferences-actions',
      content: 'Salve suas preferências ou continue para o próximo passo.',
      highlightedSelectors: ['.settings-preferences-actions'],
    },
  ],
} as const;
