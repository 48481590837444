import CloseIcon from '@mui/icons-material/Close';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button, IconButton, Badge as MuiBadge, Paper, Typography } from '@mui/material';
import { StepType, StylesObj } from '@reactour/tour';
import React, { Dispatch } from 'react';

type NavigationProps = {
  setCurrentStep: Dispatch<React.SetStateAction<number>>;
  steps: StepType[];
  currentStep: number;
  disableDots?: boolean;
  nextButton?: (props: any) => React.ReactNode | null;
  prevButton?: (props: any) => React.ReactNode | null;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
  hideButtons?: boolean;
  hideDots?: boolean;
  disableAll?: boolean;
  rtl?: boolean;
  Arrow?: React.ComponentType<any>;
};

type BadgeProps$1 = {
  styles?: StylesObj;
};

type ContentProps = {
  content: React.ReactNode;
  setCurrentStep: Dispatch<React.SetStateAction<number>>;
  setIsOpen?: Dispatch<React.SetStateAction<boolean>>;
  currentStep: number;
  transition?: boolean;
  isHighlightingObserved?: boolean;
};

// Custom components using MUI
export const Badge: React.FC<React.PropsWithChildren<BadgeProps$1>> = ({ children, styles }) => {
  return (
    <MuiBadge
      color="primary"
      badgeContent={children}
      sx={{
        '& .MuiBadge-badge': {
          backgroundColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.primary.contrastText,
        },
        ...(styles?.badge as any),
      }}
    >
      <span /> {/* MUI Badge requires a child element */}
    </MuiBadge>
  );
};

export const Close = ({ onClick }: { onClick?: () => void }) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.text.secondary,
        '&:hover': {
          color: (theme) => theme.palette.text.primary,
        },
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export const Navigation: React.FC<NavigationProps> = ({
  steps,
  currentStep,
  setCurrentStep,
  setIsOpen,
  disableAll,
  nextButton,
  prevButton,
  rtl,
}) => {
  const handlePrev = () => setCurrentStep(currentStep - 1);
  const handleNext = () => setCurrentStep(currentStep + 1);

  if (nextButton || prevButton) {
    const Button: React.FC<React.PropsWithChildren<any>> = ({ children, onClick, disabled }) => (
      <Button variant="contained" onClick={onClick} disabled={disabled || disableAll}>
        {children}
      </Button>
    );

    return (
      <div style={{ display: 'flex', gap: '8px', justifyContent: 'space-between' }}>
        {prevButton?.({
          Button,
          setCurrentStep,
          stepsLength: steps.length,
          currentStep,
          setIsOpen,
          steps,
        })}
        {nextButton?.({
          Button,
          setCurrentStep,
          stepsLength: steps.length,
          currentStep,
          setIsOpen,
          steps,
        })}
      </div>
    );
  }

  return (
    <div
      style={{ display: 'flex', gap: '8px', justifyContent: rtl ? 'flex-start' : 'space-between' }}
    >
      <Button
        startIcon={<NavigateBeforeIcon />}
        onClick={handlePrev}
        disabled={currentStep === 0 || disableAll}
        variant="contained"
      >
        Anterior
      </Button>
      <Button
        endIcon={<NavigateNextIcon />}
        onClick={handleNext}
        disabled={currentStep === steps.length - 1 || disableAll}
        variant="contained"
      >
        Próximo
      </Button>
      {currentStep === steps.length - 1 && (
        <Button variant="contained" onClick={() => setIsOpen?.(false)}>
          Finalizar
        </Button>
      )}
    </div>
  );
};

export const Content = ({ content, setIsOpen }: ContentProps) => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        pt: 6,
        minWidth: 280,
        maxWidth: 420,
        position: 'relative',
        '& > *:not(:last-child)': {
          mb: 2,
        },
      }}
    >
      <Close onClick={() => setIsOpen?.(false)} />
      <Typography variant="body1" component="div">
        {content}
      </Typography>
    </Paper>
  );
};
