import { Theme } from '@mui/material/styles';

export const getGlobalStyles = (theme: Theme) => ({
  body: {
    minHeight: '100vh',
    background:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg, #0A1929 0%, #001E3C 100%)'
        : 'linear-gradient(180deg, #E3F2FD 0%, #BBDEFB 100%)',
    '&::before': {
      content: '""',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `radial-gradient(circle at 50% 50%, ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)'} 0%, transparent 50%)`,
      opacity: 0.4,
      pointerEvents: 'none',
      zIndex: -1,
    },
  },
});
