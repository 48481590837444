import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';

const LicitacaoColumnCardSkeleton = () => {
  const InfoItemSkeleton = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
      <Skeleton variant="circular" width={20} height={20} />
      <Skeleton width={60} height={16} />
      <Skeleton width={80} height={20} />
    </Box>
  );

  return (
    <Card
      sx={{
        height: '100%',
        minHeight: '520px',
        boxShadow: 3,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', p: 2 }}>
        <Box mb={2}>
          <Box display="flex" alignItems="center" gap={1}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton width="80%" height={24} />
          </Box>
          <Box display="flex" alignItems="center" gap={1} mt={1}>
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton width="60%" height={20} />
          </Box>
        </Box>

        <Grid container spacing={1} sx={{ mb: 2 }}>
          {[...Array(8)].map((_, index) => (
            <Grid item xs={6} key={index}>
              <InfoItemSkeleton />
            </Grid>
          ))}
        </Grid>

        <Box mt={2} flex={1}>
          <Skeleton width="100%" height={100} />
          <Box mt={2}>
            <Skeleton width="95%" height={20} />
            <Skeleton width="90%" height={20} />
            <Skeleton width="85%" height={20} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default LicitacaoColumnCardSkeleton;
