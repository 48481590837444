import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getHighlightedText } from '@utils/textHighlight';
import React from 'react';

interface HighlightedTextProps {
  field: string;
  text: string;
  highlights?: Record<string, string[]>;
  variant?: 'body1' | 'body2' | 'subtitle1' | 'subtitle2';
}

const HighlightedText: React.FC<HighlightedTextProps> = ({
  field,
  text,
  highlights,
  variant = 'body2',
}) => {
  const theme = useTheme();

  if (!highlights || !highlights[field]) {
    return <Typography variant={variant}>{text}</Typography>;
  }

  const highlightedText = getHighlightedText(text, highlights[field], theme);

  return <Typography variant={variant} dangerouslySetInnerHTML={{ __html: highlightedText }} />;
};

export default HighlightedText;
