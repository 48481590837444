import Api from '@services/api';
import { DailyCount } from '@types';
import { create } from 'zustand';

interface StatsState {
  dailyCounts: DailyCount[];
  isLoading: boolean;
  error: string | null;
  totalLicitacoes: number;
  averageDailyLicitacoes: number;
  hasFetched: boolean;
  lastFetchTime: number | null;
  fetchDailyCounts: (days?: number) => Promise<void>;
}

const REFRESH_INTERVAL = 2 * 60 * 60 * 1000; // 2 hours in milliseconds

export const useStatsStore = create<StatsState>()((set, get) => ({
  dailyCounts: [],
  isLoading: false,
  error: null,
  totalLicitacoes: 0,
  averageDailyLicitacoes: 0,
  hasFetched: false,
  lastFetchTime: null,

  fetchDailyCounts: async (days = 30) => {
    const now = Date.now();
    const lastFetch = get().lastFetchTime;
    const shouldRefetch = !lastFetch || now - lastFetch > REFRESH_INTERVAL;

    if (get().hasFetched && !shouldRefetch) return;

    set({ isLoading: true, error: null });
    try {
      const counts = await Api.getDailyLicitacoesCount(days);
      const total = counts.reduce((sum, day) => sum + day.count, 0);
      const average = counts.length > 0 ? Math.round(total / counts.length) : 0;

      set({
        dailyCounts: counts,
        totalLicitacoes: total,
        averageDailyLicitacoes: average,
        isLoading: false,
        hasFetched: true,
        lastFetchTime: now,
      });
    } catch (error) {
      set({
        error: error instanceof Error ? error.message : 'Failed to fetch daily counts',
        isLoading: false,
      });
    }
  },
}));
