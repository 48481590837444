import { Box, CircularProgress } from '@mui/material';
import debounce from '@utils/debounce';
import React, { useCallback, useEffect, useRef } from 'react';

interface LoadMoreTriggerProps {
  onLoadMore: () => void;
  hasMore: boolean;
  debounceTime?: number;
}

const LoadMoreTrigger: React.FC<LoadMoreTriggerProps> = ({
  onLoadMore,
  hasMore,
  debounceTime = 2000,
}) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const debouncedOnLoadMore = useRef(
    debounce(() => {
      if (hasMore) {
        onLoadMore();
      }
    }, debounceTime),
  ).current;

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting && hasMore) {
        debouncedOnLoadMore();
      }
    },
    [hasMore, debouncedOnLoadMore],
  );

  useEffect(() => {
    if (triggerRef.current) {
      observerRef.current = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: '100px',
        threshold: 0.1,
      });

      observerRef.current.observe(triggerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      debouncedOnLoadMore.cancel();
    };
  }, [handleIntersection, debouncedOnLoadMore]);

  if (!hasMore) return null;

  return (
    <Box ref={triggerRef} display="flex" justifyContent="center" my={4}>
      <CircularProgress color="primary" />
    </Box>
  );
};

export default LoadMoreTrigger;
