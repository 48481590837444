import { ViewColumn as ColumnIcon, ViewList as ListIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

interface ViewFormatToggleProps {
  value: 'list' | 'column';
  onChange: (format: 'list' | 'column') => void;
  disabled?: boolean;
}

const ViewFormatToggle: React.FC<ViewFormatToggleProps> = ({ value, onChange, disabled }) => {
  const handleToggle = () => {
    onChange(value === 'list' ? 'column' : 'list');
  };

  return (
    <Tooltip title={value === 'list' ? 'Visualização em colunas' : 'Visualização em lista'}>
      <span>
        <IconButton
          onClick={handleToggle}
          disabled={disabled}
          color="primary"
          size="small"
          sx={{ ml: 1 }}
        >
          {value === 'list' ? <ColumnIcon /> : <ListIcon />}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ViewFormatToggle;
