import { Box, Container, useTheme } from '@mui/material';
import React from 'react';
import Header from './Header';
import RotatingLogo from './RotatingLogo';

interface LoadingProps {
  withHeader?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ withHeader = false }) => {
  const theme = useTheme();
  const loadingContent = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: withHeader ? 'calc(100vh - 64px)' : '100vh',
      }}
    >
      <Box sx={{ mb: 2 }}>
        <RotatingLogo color={theme.palette.primary.main} />
      </Box>
    </Box>
  );

  if (withHeader) {
    return (
      <Box
        sx={{
          minHeight: '100vh',
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        }}
      >
        <Header isLoading={true} />
        <Container maxWidth={false} disableGutters>
          {loadingContent}
        </Container>
      </Box>
    );
  }

  return loadingContent;
};

export default Loading;
