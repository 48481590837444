import { Alert, Button, Snackbar } from '@mui/material';
import { useProfileStore } from '@store/profileStore';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const OnboardingReminder: React.FC = () => {
  const { company, userPreferences, userData } = useProfileStore();
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const isCompanyInfoComplete = company && company.cnaePrincipal && company.cnaePrincipal.id;
  const isPreferencesComplete = userPreferences && userPreferences.companySummary;

  let message = '';
  let link = '';

  if (userData?.onboardingComplete) {
    return null;
  }

  if (!isCompanyInfoComplete) {
    message = 'Complete suas informações de empresa para melhorar sua experiência.';
    link = '/empresa/perfil?tour=1';
  } else if (!isPreferencesComplete) {
    message = 'Configure suas preferências para obter recomendações personalizadas.';
    link = '/empresa/preferencias?tour=1';
  }

  const handleClick = () => {
    setOpen(false);
    navigate(link);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={30000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{ bottom: '80px', right: '85px !important' }}
    >
      <Alert
        onClose={handleClose}
        severity="info"
        sx={{ width: '100%', cursor: 'pointer' }}
        onClick={handleClick}
        action={
          <Button
            color="inherit"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleClick();
            }}
          >
            Completar
          </Button>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default OnboardingReminder;
