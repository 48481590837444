import Api from '@services/api';
import { RecommendedLicitacao, RecommendedLicitacoes } from '@types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface RecommendedState {
  recommended: RecommendedLicitacoes[];
  recommendedList: RecommendedLicitacao[];
  isLoading: boolean;
  error: string | null;
  currentPage: number;
  totalRecommended: number;
  totalPages: number;
  itemsPerPage: number;
  viewFormat: 'list' | 'column';
  lastFetch: number | null;
  lastFetchList: number | null;
  fetchRecommended: (force?: boolean) => Promise<void>;
  fetchRecommendedLicitacoes: (page?: number, forceRefresh?: boolean) => Promise<void>;
  resetStore: () => void;
  setCurrentPage: (page: number) => void;
  setItemsPerPage: (perPage: number) => void;
  setViewFormat: (format: 'list' | 'column') => void;
}

export const useRecommendedStore = create<RecommendedState>()(
  persist(
    (set, get) => ({
      recommended: [],
      recommendedList: [],
      isLoading: false,
      error: null,
      currentPage: 1,
      totalRecommended: 0,
      totalPages: 0,
      itemsPerPage: 20,
      viewFormat: 'list',
      lastFetch: null,
      lastFetchList: null,

      fetchRecommended: async (force = false) => {
        set({ isLoading: true, error: null });
        try {
          const now = Date.now();
          const lastFetch = get().lastFetch;
          const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

          if (!force && lastFetch && now - lastFetch < CACHE_DURATION) {
            return;
          }

          const response = await Api.getRecommended();
          set({
            recommended: response.recommended,
            totalRecommended: response.total,
            isLoading: false,
            lastFetch: now,
            lastFetchList: null,
          });
        } catch (error) {
          console.error('Error fetching recommended licitacoes:', error);
          set({
            error: 'Erro ao buscar licitações recomendadas',
            isLoading: false,
            lastFetch: null,
          });
        }
      },

      fetchRecommendedLicitacoes: async (page: number = 1, forceRefresh = false) => {
        const now = Date.now();
        const lastFetchList = get().lastFetchList;
        const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

        if (!forceRefresh && page === 1 && lastFetchList && now - lastFetchList < CACHE_DURATION) {
          return;
        }

        set({ isLoading: true, error: null });
        try {
          const response = await Api.getRecommendedList(page, get().itemsPerPage);
          set({
            recommendedList: response.licitacoes,
            currentPage: page,
            totalPages: response.paginas,
            totalRecommended: response.totalLicitacoes,
            isLoading: false,
            lastFetchList: now,
          });
        } catch (error) {
          console.error('Error fetching recommended licitacoes list:', error);
          set({
            error: 'Erro ao buscar lista de licitações recomendadas',
            isLoading: false,
            totalPages: 0,
            lastFetchList: null,
          });
        }
      },

      resetStore: () =>
        set({
          recommended: [],
          isLoading: false,
          error: null,
          currentPage: 1,
          totalRecommended: 0,
          totalPages: 0,
          itemsPerPage: 20,
        }),

      setCurrentPage: (page: number) => set({ currentPage: page }),

      setItemsPerPage: (perPage: number) => {
        set({ itemsPerPage: perPage });
        get().fetchRecommendedLicitacoes(1);
      },

      setViewFormat: (format: 'list' | 'column') => set({ viewFormat: format }),
    }),
    {
      name: 'recommended-storage',
      partialize: (state) => ({
        itemsPerPage: state.itemsPerPage,
        viewFormat: state.viewFormat,
      }), // Only persist user preferences
    },
  ),
);
