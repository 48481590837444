export interface BusinessField {
  id: string;
  name: string;
  relatedCnaes: string[];
}

export const BUSINESS_FIELDS: BusinessField[] = [
  {
    id: 'agriculture',
    name: 'Agricultura e Pecuária',
    relatedCnaes: [
      '0111-3/01', // Cultivo de arroz
      '0111-3/02', // Cultivo de milho
      '0111-3/03', // Cultivo de trigo
      '0115-6/00', // Cultivo de soja
      '0159-8/02', // Criação de animais de estimação
      '0161-0/01', // Serviço de pulverização e controle de pragas agrícolas
    ],
  },
  {
    id: 'food_industry',
    name: 'Indústria Alimentícia',
    relatedCnaes: [
      '1091-1/02', // Fabricação de produtos de padaria
      '1092-9/00', // Fabricação de biscoitos e bolachas
      '1093-7/01', // Fabricação de produtos derivados do cacau e de chocolates
      '1094-5/00', // Fabricação de massas alimentícias
      '1095-3/00', // Fabricação de especiarias
    ],
  },
  {
    id: 'health',
    name: 'Saúde e Equipamentos Médicos',
    relatedCnaes: [
      '3250-7/01', // Fabricação de instrumentos não-eletrônicos para uso médico
      '4645-1/01', // Comércio atacadista de instrumentos e materiais médico-cirúrgicos
      '4645-1/02', // Comércio atacadista de próteses e artigos de ortopedia
      '4645-1/03', // Comércio atacadista de produtos odontológicos
      '4773-3/00', // Comércio varejista de artigos médicos e ortopédicos
    ],
  },
  {
    id: 'technology',
    name: 'Tecnologia e Informática',
    relatedCnaes: [
      '6201-5/01', // Desenvolvimento de programas de computador sob encomenda
      '6202-3/00', // Desenvolvimento e licenciamento de programas customizáveis
      '6204-0/00', // Consultoria em tecnologia da informação
      '6209-1/00', // Suporte técnico em tecnologia da informação
      '4751-2/01', // Comércio varejista de equipamentos de informática
      '6190-6/01', // Provedores de acesso às redes de comunicações
      '6110-8/01', // Serviços de telefonia fixa comutada
      '6120-5/01', // Telefonia móvel celular
      '6130-2/00', // Telecomunicações por satélite
      '6141-8/00', // Operadoras de televisão por assinatura
    ],
  },
  {
    id: 'construction',
    name: 'Construção Civil',
    relatedCnaes: [
      '4120-4/00', // Construção de edifícios
      '4211-1/01', // Construção de rodovias
      '4213-8/00', // Obras de urbanização
      '4321-5/00', // Instalação e manutenção elétrica
      '4322-3/01', // Instalações hidráulicas, sanitárias e de gás
    ],
  },
  {
    id: 'transportation',
    name: 'Transportes e Logística',
    relatedCnaes: [
      '4930-2/01', // Transporte rodoviário de carga municipal
      '4930-2/02', // Transporte rodoviário de carga intermunicipal
      '5211-7/01', // Armazéns gerais
      '5229-0/02', // Serviços de reboque de veículos
      '5320-2/02', // Serviços de entrega rápida
      '5111-1/00', // Transporte aéreo de passageiros regular
      '5120-0/00', // Transporte aéreo de carga
      '5021-1/01', // Transporte por navegação interior de carga
      '5030-1/01', // Navegação de apoio marítimo
      '4912-4/01', // Transporte ferroviário de carga
    ],
  },
  {
    id: 'education',
    name: 'Educação e Treinamento',
    relatedCnaes: [
      '8511-2/00', // Educação infantil - creche
      '8512-1/00', // Educação infantil - pré-escola
      '8531-7/00', // Educação superior - graduação
      '8599-6/04', // Treinamento em desenvolvimento profissional
      '8599-6/05', // Cursos preparatórios para concursos
    ],
  },
  {
    id: 'cleaning',
    name: 'Limpeza e Conservação',
    relatedCnaes: [
      '8121-4/00', // Limpeza em prédios e domicílios
      '8122-2/00', // Imunização e controle de pragas urbanas
      '8129-0/00', // Atividades de limpeza
      '9601-7/01', // Lavanderias
      '9601-7/02', // Tinturarias
    ],
  },
  {
    id: 'security',
    name: 'Segurança e Proteção',
    relatedCnaes: [
      '8011-1/01', // Atividades de vigilância e segurança privada
      '8020-0/01', // Atividades de monitoramento de sistemas de segurança
      '8020-0/02', // Outras atividades de serviços de segurança
      '3292-2/01', // Fabricação de roupas de proteção e segurança
      '3292-2/02', // Fabricação de equipamentos de segurança
    ],
  },
  {
    id: 'furniture',
    name: 'Móveis e Decoração',
    relatedCnaes: [
      '3101-2/00', // Fabricação de móveis com predominância de madeira
      '3102-1/00', // Fabricação de móveis com predominância de metal
      '3103-9/00', // Fabricação de móveis de outros materiais
      '4754-7/01', // Comércio varejista de móveis
      '4754-7/03', // Comércio varejista de artigos de iluminação
    ],
  },
  {
    id: 'environmental',
    name: 'Meio Ambiente e Sustentabilidade',
    relatedCnaes: [
      '3811-4/00', // Coleta de resíduos não-perigosos
      '3812-2/00', // Coleta de resíduos perigosos
      '3821-1/00', // Tratamento e disposição de resíduos não-perigosos
      '3832-7/00', // Recuperação de materiais plásticos
      '3839-4/01', // Usinas de compostagem
    ],
  },
  {
    id: 'maintenance',
    name: 'Manutenção e Reparação',
    relatedCnaes: [
      '9511-8/00', // Reparação de computadores
      '9512-6/00', // Reparação de equipamentos de comunicação
      '9521-5/00', // Reparação de equipamentos eletroeletrônicos
      '9529-1/05', // Reparação de artigos do mobiliário
      '9529-1/99', // Reparação de outros objetos
    ],
  },
  {
    id: 'consulting',
    name: 'Consultoria e Gestão',
    relatedCnaes: [
      '7020-4/00', // Atividades de consultoria em gestão empresarial
      '6911-7/01', // Serviços advocatícios
      '6920-6/01', // Atividades de contabilidade
      '7112-0/00', // Serviços de engenharia
      '7119-7/01', // Serviços de cartografia e topografia
    ],
  },
  {
    id: 'textile',
    name: 'Têxtil e Vestuário',
    relatedCnaes: [
      '1311-1/00', // Preparação e fiação de fibras de algodão
      '1321-9/00', // Tecelagem de fios de algodão
      '1412-6/01', // Confecção de peças de vestuário
      '1413-4/01', // Confecção de roupas profissionais
      '1422-3/00', // Fabricação de artigos do vestuário
    ],
  },
  {
    id: 'automotive',
    name: 'Automotivo',
    relatedCnaes: [
      '4511-1/01', // Comércio a varejo de automóveis
      '4520-0/01', // Serviços de manutenção e reparação mecânica
      '4530-7/01', // Comércio por atacado de peças para veículos
      '4541-2/01', // Comércio por atacado de motocicletas
      '4543-9/00', // Manutenção e reparação de motocicletas
    ],
  },
  {
    id: 'chemical',
    name: 'Indústria Química',
    relatedCnaes: [
      '2011-8/00', // Fabricação de produtos químicos inorgânicos
      '2013-4/00', // Fabricação de adubos e fertilizantes
      '2021-5/00', // Fabricação de produtos petroquímicos básicos
      '2031-2/00', // Fabricação de resinas termoplásticas
      '2040-1/00', // Fabricação de fibras artificiais e sintéticas
    ],
  },
  {
    id: 'pharmaceutical',
    name: 'Farmacêutico',
    relatedCnaes: [
      '2121-1/01', // Fabricação de medicamentos alopáticos
      '2121-1/02', // Fabricação de medicamentos homeopáticos
      '2121-1/03', // Fabricação de medicamentos fitoterápicos
      '2123-8/00', // Fabricação de preparações farmacêuticas
      '4644-3/01', // Comércio atacadista de medicamentos
    ],
  },
  {
    id: 'sports',
    name: 'Esportes e Recreação',
    relatedCnaes: [
      '9311-5/00', // Gestão de instalações de esportes
      '9312-3/00', // Clubes sociais, esportivos e similares
      '9313-1/00', // Atividades de condicionamento físico
      '9319-1/01', // Produção e promoção de eventos esportivos
      '8591-1/00', // Ensino de esportes
    ],
  },
  {
    id: 'printing',
    name: 'Gráfica e Impressão',
    relatedCnaes: [
      '1811-3/01', // Impressão de jornais
      '1812-1/00', // Impressão de material de segurança
      '1813-0/01', // Impressão de material para uso publicitário
      '1821-1/00', // Serviços de pré-impressão
      '1822-9/01', // Serviços de encadernação e plastificação
    ],
  },
  {
    id: 'metal',
    name: 'Metalurgia e Siderurgia',
    relatedCnaes: [
      '2411-3/00', // Produção de ferro-gusa
      '2421-1/00', // Produção de semi-acabados de aço
      '2431-8/00', // Fundição de ferro e aço
      '2441-5/02', // Produção de laminados de alumínio
      '2451-2/00', // Fundição de ferro e aço
    ],
  },
  {
    id: 'electronics',
    name: 'Eletrônicos e Eletrodomésticos',
    relatedCnaes: [
      '2621-3/00', // Fabricação de equipamentos de informática
      '2640-0/00', // Fabricação de aparelhos de áudio e vídeo
      '2710-4/01', // Fabricação de geradores e transformadores
      '2751-1/00', // Fabricação de fogões, refrigeradores
      '2759-7/01', // Fabricação de aparelhos elétricos
    ],
  },
  {
    id: 'packaging',
    name: 'Embalagens',
    relatedCnaes: [
      '1731-1/00', // Fabricação de embalagens de papel
      '2221-8/00', // Fabricação de embalagens de plástico
      '1732-0/00', // Fabricação de embalagens de cartolina e papel-cartão
      '2222-6/00', // Fabricação de embalagens de material plástico
      '2312-5/00', // Fabricação de embalagens de vidro
      '2591-8/00', // Fabricação de embalagens metálicas
    ],
  },
  {
    id: 'laboratory',
    name: 'Laboratórios e Análises',
    relatedCnaes: [
      '7120-1/00', // Testes e análises técnicas
      '7210-0/00', // Pesquisa e desenvolvimento experimental
      '8640-2/02', // Laboratórios clínicos
      '8640-2/01', // Laboratórios de anatomia patológica
      '8640-2/03', // Serviços de diagnóstico por imagem
    ],
  },
  {
    id: 'events',
    name: 'Eventos e Cultura',
    relatedCnaes: [
      '8230-0/01', // Serviços de organização de feiras
      '9001-9/01', // Produção teatral
      '9001-9/02', // Produção musical
      '9001-9/03', // Produção de espetáculos de dança
      '9003-5/00', // Gestão de espaços para artes cênicas
    ],
  },
  {
    id: 'telecom',
    name: 'Telecomunicações',
    relatedCnaes: [
      '6110-8/01', // Serviços de telefonia fixa comutada
      '6120-5/01', // Telefonia móvel celular
      '6130-2/00', // Telecomunicações por satélite
      '6141-8/00', // Operadoras de televisão por assinatura
      '6190-6/01', // Provedores de acesso às redes de comunicações
    ],
  },
  {
    id: 'beauty',
    name: 'Beleza e Estética',
    relatedCnaes: [
      '9602-5/01', // Cabeleireiros, manicure e pedicure
      '9602-5/02', // Atividades de estética e outros serviços de cuidados com a beleza
      '4772-5/00', // Comércio varejista de cosméticos e perfumaria
      '4646-0/01', // Comércio atacadista de cosméticos e produtos de perfumaria
      '2063-1/00', // Fabricação de cosméticos e produtos de perfumaria
    ],
  },
  {
    id: 'funeral',
    name: 'Serviços Funerários',
    relatedCnaes: [
      '9603-3/01', // Gestão e manutenção de cemitérios
      '9603-3/02', // Serviços de cremação
      '9603-3/03', // Serviços de sepultamento
      '9603-3/04', // Serviços de funerárias
      '9603-3/99', // Atividades funerárias e serviços relacionados
    ],
  },
  {
    id: 'pet',
    name: 'Serviços para Animais',
    relatedCnaes: [
      '9609-2/07', // Alojamento de animais domésticos
      '9609-2/08', // Higiene e embelezamento de animais domésticos
      '7500-1/00', // Atividades veterinárias
      '4789-0/04', // Comércio varejista de animais vivos e artigos para animais
      '1066-0/00', // Fabricação de alimentos para animais
    ],
  },
  {
    id: 'religious',
    name: 'Organizações Religiosas',
    relatedCnaes: [
      '9491-0/00', // Atividades de organizações religiosas ou filosóficas
      '9493-6/00', // Atividades de organizações associativas ligadas à cultura e à arte
      '9499-5/00', // Atividades associativas não especificadas anteriormente
      '8550-3/02', // Atividades de apoio à educação
      '9430-8/00', // Atividades de associações de defesa de direitos sociais
    ],
  },
  {
    id: 'professional_associations',
    name: 'Associações Profissionais',
    relatedCnaes: [
      '9411-1/00', // Atividades de organizações associativas patronais e empresariais
      '9412-0/01', // Atividades de fiscalização profissional
      '9412-0/99', // Outras atividades associativas profissionais
      '9420-1/00', // Atividades de organizações sindicais
      '9430-8/00', // Atividades de associações de defesa de direitos sociais
    ],
  },
  {
    id: 'repair_services',
    name: 'Serviços de Reparação',
    relatedCnaes: [
      '9529-1/01', // Reparação de calçados, bolsas e artigos de viagem
      '9529-1/02', // Chaveiros
      '9529-1/03', // Reparação de relógios
      '9529-1/04', // Reparação de bicicletas, triciclos e outros veículos
      '9529-1/06', // Reparação de jóias
    ],
  },
  {
    id: 'personal_services',
    name: 'Serviços Pessoais',
    relatedCnaes: [
      '9601-7/01', // Lavanderias
      '9601-7/02', // Tinturarias
      '9601-7/03', // Toalheiros
      '9609-2/05', // Atividades de sauna e banhos
      '9609-2/06', // Serviços de tatuagem e colocação de piercing
    ],
  },
  {
    id: 'domestic_services',
    name: 'Serviços Domésticos',
    relatedCnaes: [
      '9700-5/00', // Serviços domésticos
      '8121-4/00', // Limpeza em prédios e em domicílios
      '9609-2/99', // Outras atividades de serviços pessoais
      '5620-1/02', // Serviços de alimentação para eventos e recepções
      '8800-6/00', // Serviços de assistência social sem alojamento
    ],
  },
  {
    id: 'medical_services',
    name: 'Serviços Médicos',
    relatedCnaes: [
      '8610-1/01', // Atividades de atendimento hospitalar
      '8630-5/01', // Atividade médica ambulatorial com recursos para procedimentos
      '8630-5/02', // Atividade médica ambulatorial com recursos para exames
      '8630-5/03', // Atividade médica ambulatorial restrita a consultas
      '8650-0/01', // Atividades de enfermagem
      '8650-0/02', // Atividades de profissionais da nutrição
      '8650-0/03', // Atividades de psicologia e psicanálise
      '8650-0/04', // Atividades de fisioterapia
      '8650-0/05', // Atividades de terapia ocupacional
      '8650-0/06', // Atividades de fonoaudiologia
      '8630-5/04', // Atividade odontológica
      '8640-2/01', // Laboratórios de anatomia patológica
      '8640-2/02', // Laboratórios clínicos
      '8640-2/03', // Serviços de diagnóstico por imagem
      '8621-6/01', // UTI móvel
      '8621-6/02', // Serviços móveis de atendimento a urgências
    ],
  },
  {
    id: 'dental_services',
    name: 'Serviços Odontológicos',
    relatedCnaes: [
      '8630-5/06', // Serviços de prótese dentária
      '8650-0/07', // Atividades de terapia de nutrição enteral e parenteral
      '3250-7/05', // Fabricação de materiais para medicina e odontologia
    ],
  },
  {
    id: 'garden_and_floriculture',
    name: 'Jardinagem e Floricultura',
    relatedCnaes: [
      '0122-9/00', // Floricultura
      '8130-3/00', // Atividades paisagísticas
      '0142-3/00', // Produção de mudas e outras formas de propagação vegetal
      '4789-0/02', // Comércio varejista de plantas e flores naturais
      '0161-0/02', // Serviço de poda de árvores para lavouras
      '0161-0/03', // Serviço de preparação de terreno, cultivo e colheita
    ],
  },
  {
    id: 'emergency_services',
    name: 'Serviços de Emergência',
    relatedCnaes: [
      '8621-6/01', // UTI móvel
      '8621-6/02', // Serviços móveis de atendimento a urgências
      '8622-4/00', // Serviços de remoção de pacientes
      '8640-2/99', // Atividades de serviços de complementação diagnóstica e terapêutica
      '8610-1/02', // Atividades de atendimento em pronto-socorro
    ],
  },
  {
    id: 'entertainment',
    name: 'Entretenimento e Mídia',
    relatedCnaes: [
      '5911-1/01', // Estúdios cinematográficos
      '5912-0/01', // Serviços de dublagem
      '5913-8/00', // Distribuição cinematográfica, de vídeo e de programas de televisão
      '5914-6/00', // Atividades de exibição cinematográfica
      '6010-1/00', // Atividades de rádio
    ],
  },
  {
    id: 'real_estate',
    name: 'Imobiliário',
    relatedCnaes: [
      '6810-2/01', // Compra e venda de imóveis próprios
      '6810-2/02', // Aluguel de imóveis próprios
      '6821-8/01', // Corretagem na compra e venda de imóveis
      '6822-6/00', // Gestão e administração da propriedade imobiliária
      '4110-7/00', // Incorporação de empreendimentos imobiliários
    ],
  },
  {
    id: 'tourism',
    name: 'Turismo e Hotelaria',
    relatedCnaes: [
      '7911-2/00', // Agências de viagens
      '7912-1/00', // Operadores turísticos
      '5510-8/01', // Hotéis
      '5590-6/01', // Albergues, exceto assistenciais
      '5590-6/03', // Pensões (alojamento)
    ],
  },
  {
    id: 'financial',
    name: 'Serviços Financeiros',
    relatedCnaes: [
      '6419-6/00', // Bancos múltiplos, com carteira comercial
      '6422-1/00', // Bancos múltiplos, sem carteira comercial
      '6424-7/01', // Bancos cooperativos
      '6436-1/00', // Sociedades de crédito, financiamento e investimento
      '6438-7/01', // Bancos de câmbio
    ],
  },
  {
    id: 'insurance',
    name: 'Seguros e Previdência',
    relatedCnaes: [
      '6511-1/01', // Seguros de vida
      '6512-0/00', // Seguros não-vida
      '6520-1/00', // Seguros-saúde
      '6530-8/00', // Resseguros
      '6541-3/00', // Previdência complementar fechada
    ],
  },
  {
    id: 'food_services',
    name: 'Serviços de Alimentação',
    relatedCnaes: [
      '5611-2/01', // Restaurantes e similares
      '5611-2/02', // Bares e outros estabelecimentos
      '5612-1/00', // Serviços ambulantes de alimentação
      '5620-1/01', // Fornecimento de alimentos preparados
      '5620-1/03', // Cantinas - serviços de alimentação privativos
    ],
  },
  {
    id: 'mining',
    name: 'Mineração e Extração',
    relatedCnaes: [
      '0710-3/01', // Extração de minério de ferro
      '0810-0/01', // Extração de ardósia e beneficiamento
      '0891-6/00', // Extração de minerais para fabricação de adubos
      '0899-1/01', // Extração de grafita
      '0899-1/99', // Extração de outros minerais não-metálicos
    ],
  },
  {
    id: 'research',
    name: 'Pesquisa e Desenvolvimento',
    relatedCnaes: [
      '7210-0/00', // Pesquisa e desenvolvimento experimental em ciências físicas e naturais
      '7220-7/00', // Pesquisa e desenvolvimento experimental em ciências sociais e humanas
      '7490-1/99', // Outras atividades profissionais, científicas e técnicas
      '8640-2/02', // Laboratórios clínicos
      '7120-1/00', // Testes e análises técnicas
    ],
  },
  {
    id: 'energy',
    name: 'Energia e Utilities',
    relatedCnaes: [
      '3511-5/01', // Geração de energia elétrica
      '3512-3/00', // Transmissão de energia elétrica
      '3513-1/00', // Comércio atacadista de energia elétrica
      '3520-4/01', // Produção de gás; processamento de gás natural
      '3600-6/01', // Captação, tratamento e distribuição de água
    ],
  },
];

export const getCnaesByField = (fieldId: string): string[] => {
  const field = BUSINESS_FIELDS.find((f) => f.id === fieldId);
  return field?.relatedCnaes || [];
};

export const getFieldsByCnae = (cnae: string): BusinessField[] => {
  return BUSINESS_FIELDS.filter((field) => field.relatedCnaes.includes(cnae));
};
