import Api from '@services/api';
import { LicitacoesSearchParams, SavedSearch } from '@types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface SavedSearchesState {
  savedSearches: SavedSearch[];
  isLoading: boolean;
  error: string | null;
  lastFetch: number | null;
  fetchSavedSearches: (force?: boolean) => Promise<void>;
  addSavedSearch: (name: string, params: LicitacoesSearchParams) => Promise<void>;
  removeSavedSearch: (id: string) => Promise<void>;
  updateSavedSearch: (id: string, name: string, params: LicitacoesSearchParams) => Promise<void>;
  getPreferencesAsSearch: (userPreferences: any) => SavedSearch;
  resetStore: () => void;
}

export const useSavedSearchesStore = create<SavedSearchesState>()(
  persist(
    (set, get) => ({
      savedSearches: [],
      isLoading: false,
      error: null,
      lastFetch: null,

      fetchSavedSearches: async (force = false) => {
        const lastFetch = get().lastFetch;
        const now = Date.now();

        // Cache for 5 minutes
        if (lastFetch && now - lastFetch < 5 * 60 * 1000 && !force) {
          return;
        }

        set({ isLoading: true, error: null });
        try {
          const savedSearches = await Api.getSavedSearches();
          set({ savedSearches, isLoading: false, lastFetch: now });
        } catch (error) {
          console.error('Error fetching saved searches:', error);
          set({ error: 'Erro ao buscar buscas salvas', isLoading: false });
        }
      },

      addSavedSearch: async (name: string, params: LicitacoesSearchParams) => {
        set({ isLoading: true, error: null });
        try {
          const newSavedSearch = await Api.addSavedSearch(name, params);
          set((state) => ({
            savedSearches: [...state.savedSearches, newSavedSearch],
            isLoading: false,
          }));
        } catch (error) {
          console.error('Error adding saved search:', error);
          set({ error: 'Erro ao adicionar busca salva', isLoading: false });
        }
      },

      removeSavedSearch: async (id: string) => {
        set({ isLoading: true, error: null });
        try {
          await Api.removeSavedSearch(id);
          set((state) => ({
            savedSearches: state.savedSearches.filter((search) => search.id !== id),
            isLoading: false,
          }));
        } catch (error) {
          console.error('Error removing saved search:', error);
          set({ error: 'Erro ao remover busca salva', isLoading: false });
        }
      },

      updateSavedSearch: async (id: string, name: string, params: LicitacoesSearchParams) => {
        set({ isLoading: true, error: null });
        try {
          const updatedSearch = await Api.updateSavedSearch(id, name, params);
          set((state) => ({
            savedSearches: state.savedSearches.map((search) =>
              search.id === id ? updatedSearch : search,
            ),
            isLoading: false,
          }));
        } catch (error) {
          console.error('Error updating saved search:', error);
          set({ error: 'Erro ao atualizar busca salva', isLoading: false });
        }
      },

      getPreferencesAsSearch: (userPreferences) => ({
        id: 'preferences',
        name: 'Minhas Preferências',
        params: {
          uf: userPreferences?.states || [],
          municipio_ibge: userPreferences?.cities || [],
          modalidade: userPreferences?.modalidades || [],
          palavra_chave: userPreferences?.keywords || [],
          id_portal: userPreferences?.portals || [],
          esfera: userPreferences?.esferas || [],
        },
      }),

      resetStore: () => {
        set({ savedSearches: [], isLoading: false, error: null });
      },
    }),
    {
      name: 'saved-searches-storage',
      partialize: (state) => ({
        savedSearches: state.savedSearches,
        lastFetch: state.lastFetch,
      }),
    },
  ),
);
