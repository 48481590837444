import { Delete, Save, Settings } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useProfileStore } from '@store/profileStore';
import { useSavedSearchesStore } from '@store/savedSearchesStore';
import { LicitacoesSearchParams } from '@types';
import isEqual from '@utils/isEqual';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface SavedSearchesProps {
  currentSearchParams: LicitacoesSearchParams;
  onSearchSelect: (params: LicitacoesSearchParams, searchId: string) => void;
  activeSearchId: string | null;
}

const SavedSearches: React.FC<SavedSearchesProps> = ({
  currentSearchParams,
  onSearchSelect,
  activeSearchId,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { userPreferences, currentProfileId } = useProfileStore();
  const {
    savedSearches,
    fetchSavedSearches,
    addSavedSearch,
    removeSavedSearch,
    updateSavedSearch,
    getPreferencesAsSearch,
  } = useSavedSearchesStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [editingSearch, setEditingSearch] = useState<string | null>(null);
  const [deletingSearch, setDeletingSearch] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchSavedSearches();
  }, [fetchSavedSearches, currentProfileId]);

  const handleSaveSearch = () => {
    if (searchName.length <= 3) {
      setError('O nome da busca deve ter mais de 3 caracteres');
      return;
    }
    if (editingSearch) {
      updateSavedSearch(editingSearch, searchName, currentSearchParams);
    } else {
      addSavedSearch(searchName, currentSearchParams);
    }
    setIsModalOpen(false);
    setSearchName('');
    setEditingSearch(null);
  };

  const handleDeleteSearch = (id: string) => {
    setDeletingSearch(id);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteSearch = () => {
    if (deletingSearch) {
      removeSavedSearch(deletingSearch);
    }
    setIsDeleteModalOpen(false);
    setDeletingSearch(null);
  };

  const handlePreferencesClick = () => {
    if (userPreferences) {
      onSearchSelect(getPreferencesAsSearch(userPreferences).params, 'preferences');
    } else {
      navigate('/empresa/preferencias');
    }
  };

  const hasSearchParams = useMemo(
    () =>
      Object.keys(currentSearchParams).some(
        (key) =>
          currentSearchParams[key as keyof LicitacoesSearchParams] !== undefined &&
          currentSearchParams[key as keyof LicitacoesSearchParams] !== null &&
          currentSearchParams[key as keyof LicitacoesSearchParams] !== '' &&
          (!Array.isArray(currentSearchParams[key as keyof LicitacoesSearchParams]) ||
            (currentSearchParams[key as keyof LicitacoesSearchParams] as any).length > 0),
      ),
    [currentSearchParams],
  );

  const normalizeParams = useCallback((params: LicitacoesSearchParams) => {
    const normalized = { ...params };

    Object.entries(normalized).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length === 0) {
        delete normalized[key as keyof LicitacoesSearchParams];
      } else if (value === '' || value === undefined || value === null) {
        delete normalized[key as keyof LicitacoesSearchParams];
      } else if (typeof value === 'boolean') {
        normalized[key as keyof LicitacoesSearchParams] = value as any;
      }
    });

    delete normalized.pagina;
    delete normalized.licitacoesPorPagina;
    delete normalized.sortBy;

    return normalized;
  }, []);

  const isSearchActive = useCallback(
    (searchParams: LicitacoesSearchParams) => {
      const normalizedCurrent = normalizeParams(currentSearchParams);
      const normalizedSearch = normalizeParams(searchParams);
      return isEqual(normalizedCurrent, normalizedSearch);
    },
    [currentSearchParams, normalizeParams],
  );

  const isPreferencesActive = useMemo(() => {
    if (!userPreferences) return false;
    const preferencesSearch = getPreferencesAsSearch(userPreferences);
    return isSearchActive(preferencesSearch.params);
  }, [userPreferences, isSearchActive]);

  const isDarkMode = theme.palette.mode === 'dark';
  const bgColor = useMemo(
    () => (isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.08)'),
    [isDarkMode],
  );

  const savedSearchesChips = useMemo(
    () =>
      savedSearches?.map((search) => (
        <Chip
          key={search.id}
          label={search.name}
          onClick={() => onSearchSelect(search.params, search.id)}
          onDelete={() => handleDeleteSearch(search.id)}
          deleteIcon={<Delete fontSize="small" />}
          color={activeSearchId === search.id ? 'primary' : 'default'}
        />
      )),
    [savedSearches, activeSearchId, onSearchSelect, handleDeleteSearch, bgColor, theme],
  );

  return (
    <Box
      className="saved-searches"
      display="flex"
      alignItems="flex-start"
      justifyContent="space-between"
      sx={{
        minHeight: 'fit-content',
        flexWrap: 'wrap',
        gap: 2,
      }}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        gap={1}
        flexGrow={1}
        alignItems="center"
        sx={{
          maxWidth: 'calc(100% - 120px)',
          minHeight: 32,
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            color: 'text.secondary',
            mr: 1,
            minWidth: 'fit-content',
          }}
        >
          Buscas Salvas:
        </Typography>
        <Chip
          icon={<Settings fontSize="small" />}
          label="Minhas Preferências"
          onClick={handlePreferencesClick}
          sx={{
            backgroundColor: isPreferencesActive
              ? 'primary'
              : userPreferences
                ? 'rgba(0, 0, 0, 0.08)'
                : 'grey.300',
            color: isPreferencesActive || userPreferences ? 'primary.contrastText' : 'text.primary',
            '&:hover': {
              backgroundColor: isPreferencesActive
                ? 'primary'
                : userPreferences
                  ? 'rgba(0, 0, 0, 0.12)'
                  : 'grey.400',
            },
          }}
        />
        {savedSearches && savedSearches.length > 0 ? (
          savedSearchesChips
        ) : (
          <Typography>Nenhuma busca salva</Typography>
        )}
      </Box>
      <Button
        className="save-search-button"
        variant="outlined"
        onClick={() => setIsModalOpen(true)}
        startIcon={<Save fontSize="small" />}
        disabled={!hasSearchParams}
        sx={{
          marginLeft: 'auto',
          padding: '4px 8px',
          minWidth: 'unset',
          height: '32px',
          fontSize: '0.8125rem',
          opacity: !hasSearchParams ? 0.5 : 1,
          pointerEvents: !hasSearchParams ? 'none' : 'auto',
        }}
      >
        Salvar Busca
      </Button>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>{editingSearch ? 'Editar Busca Salva' : 'Salvar Busca'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            error={!!error}
            helperText={error}
            label="Nome da Busca"
            type="text"
            fullWidth
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleSaveSearch}>{editingSearch ? 'Atualizar' : 'Salvar'}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>Tem certeza que deseja excluir esta busca salva?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteModalOpen(false)}>Cancelar</Button>
          <Button onClick={confirmDeleteSearch} color="error">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SavedSearches;
